export const environment = {
  SERVER_ORIGIN: '',
  production: true,
  useHash: false,
  hmr: false,
  // URL: 'http://localhost:8081/api/bot/',
  VALIDATOR: 'QCu7o8t1pJ80n2MJGZqVh8jjxd+GqDj4',
  URL: 'https://bot.gateway2it.com/gbot/api/bot/',
  IMAGE_BASE_URL: 'https://bot.gateway2it.com/gbot/assets/images/gbot/',
  MAX_FILE_SIZE: 1 * 1024 * 1024
};