import { Grid } from "@mui/material";
import React from "react";
import './Style.css';

const YesNo = () => {
    return(
        <>
            <Grid container>
                <Grid item md={12}>
                    <h1>dsfsdf</h1>
                </Grid>
            </Grid>
        </>
    );
}

export default YesNo;