import { AccountBalanceWallet, MarkEmailUnread, Category, Dashboard, Group, IntegrationInstructions, Logout, Security, Settings, Subscriptions, Translate } from "@mui/icons-material";
import { LINK } from "../../utils/apiConfig";

export const MENU = [
  {
    menu: "Dashboard",
    link: LINK.HOME,
    icon: <Dashboard />
  },
  {
    menu: "Industry",
    link: LINK.INDUSTRY,
    icon: <Category />
  },
  // {
  //   menu: "Language",
  //   link: LINK.LANGUAGE,
  //   icon: <Translate />
  // },
  {
    menu: "Subscription",
    link: LINK.SUBSCRIPTION,
    icon: <Subscriptions />
  },
  {
    menu: "Client",
    link: LINK.CLIENT,
    icon: <Group />
  },
  {
    menu: "User Queries",
    link: LINK.USER_QUERY,
    icon: <MarkEmailUnread />
  },
  {
    menu: "Bots",
    link: LINK.BOTS,
    icon: <Security />
  },
  {
    menu: "Billing",
    link: LINK.BILLING,
    icon: <AccountBalanceWallet />
  },
  {
    menu: "Setting",
    link: LINK.SETTING,
    icon: <Settings />
  },
  {
    menu: "Documents",
    link: LINK.DOCUMENTS,
    icon: <IntegrationInstructions />
  },
  {
    menu: "Templates",
    link: LINK.TEMPLATES,
    icon: <Security />
  },
  {
    menu: "Question",
    link: LINK.QUESTION,
    icon: <Security />
  },
  {
    menu: "Logout",
    link: LINK.LOGOUT,
    icon: <Logout />
  },
  {
    menu: "Add Questions",
    link: LINK.ADD_QUESTIONS,
    icon: <Logout/>
  }
];

  