import { Button, Grid } from "@mui/material";
import "./document.css";
import { useState } from "react";

const steps = [
    {
        label: 'Visit our Website',
        description: `Begin by navigating to our website. Simply click on this link: <a href="https://chat.gateway2it.com" target="_blank" style="font-weight: bold; text-decoration: none;">chat.gateway2it.com</a>. This will open the site in a new tab where you can explore our services and offerings.`,
    },
    {
        label: 'Sign-Up Yourself',
        description: "Once you're on our website, proceed to sign up. Look for the sign-up button or link. During this process, you'll need to provide some basic information to create your account. Remember, we will send password on your email after you can login using that.",
    },
    {
        label: 'Purchase a Subscription',
        description: `After successfully creating your account, log in with your new credentials. Navigate to the subscription section where you can choose and purchase a plan that suits your needs. This subscription will give you access to all the premium features of our platform.`,
    },
    {
        label: 'Create a New Bot',
        description: `With your subscription active, you can now create a new bot. Head over to the bot menu in your dashboard. Follow the instructions to set up your bot, tailoring it to your specific requirements. This step will enable you to leverage the full capabilities of our chat services.`,
    }

];

const pluginStep = [
    {
        label: 'Plugin Bot',
        description: 'We are excited to introduce the "Plugin Bot" feature. Upon receiving an email containing a script code, you simply need to add this script to the body section of your index.html file. This will seamlessly integrate the bot into your website, allowing for easy and direct interaction with the bot through your webpage.'
    },
    {
        label: 'Now You are Ready',
        description: `Congratulations! You have successfully completed all the previous steps. You are now ready to use our services. With your account set up and your subscription active, you can fully explore and utilize all the features available on our platform. Enjoy creating and managing your bots, and take advantage of the robust tools we offer to enhance your experience. If you need any assistance, our support team is always here to help.`,
    }
]

const webpageStep = [
    {
        label: 'Webpage Bot',
        description: 'We are excited to introduce the "Webpage Bot" feature. When you receive an email containing a URL and click on it, you will be able to access and interact with your bot directly through a webpage. This feature enhances the usability and accessibility of your bot, making it easier than ever to engage with the bot through your web browser.'
    },
    {
        label: 'Now You are Ready',
        description: `Congratulations! You have successfully completed all the previous steps. You are now ready to use our services. With your account set up and your subscription active, you can fully explore and utilize all the features available on our platform. Enjoy creating and managing your bots, and take advantage of the robust tools we offer to enhance your experience. If you need any assistance, our support team is always here to help.`,
    }
]
export default function Document() {
    const [isWebpage, setIsWebpage] = useState(true);
    return <>
        <Grid container spacing={2}>
            <Grid item lg={7} md={7} sm={12} xs={12}>
                {steps.map((item, index) =>
                    <div key={index} className="step-item-container">
                        <div >
                            <span style={{ fontWeight: 600, fontSize: "large" }}>Step: {index + 1} </span>
                            {item.label}
                            <hr style={{ backgroundColor: "red" }}></hr>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                )}
                <div className="variant-button">
                    <Button variant={isWebpage ? "contained" : "outlined"} onClick={() => setIsWebpage(true)}>Webpage</Button>
                    <Button variant={!isWebpage ? "contained" : "outlined"} onClick={() => setIsWebpage(false)}>Plugin</Button>
                </div>
                {isWebpage && webpageStep.map((item, index) =>
                    <div key={index} className="step-item-container">
                        <div >
                            <span style={{ fontWeight: 600, fontSize: "large" }}>Step: {steps.length + 1 + index} </span>
                            {item.label}
                            <hr style={{ backgroundColor: "red" }}></hr>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                )}
                {!isWebpage && pluginStep.map((item, index) =>
                    <div key={index} className="step-item-container">
                        <div >
                            <span style={{ fontWeight: 600, fontSize: "large" }}>Step: {steps.length + 1 + index} </span>
                            {item.label}
                            <hr style={{ backgroundColor: "red" }}></hr>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                )}
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12}>
                <div className="description-container">
                    <h1>Coming Soon</h1>
                </div>
                <div className="description-container">
                    <h1>Coming Soon</h1>
                </div>
            </Grid>
            
        </Grid>
    </>
}
