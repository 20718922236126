import React from "react";

const Layout = ({children}: any) => {
  return (
    <>
        <div className="container">
          {children}
        </div>
    </>
  );
};

export default Layout;
