import { AddReaction } from "@mui/icons-material";

export const BANNER = {
  heading: "Welcome to GBOT",
  title: "Your 24/7 Personal Assistant – Instant Answers, Anytime, Anywhere",
  subtitle:
    "GBOT is an Smart Agent provides instant, accurate answers for customers 24/7. GBOT is a totally new way to deliver customer service. The entire GBOT platform is powered by Smart technology so customers get instant support with an Smart agent.",
  tag: "Contact us today to see how GBOT can create the perfect chatbot for your business.",
  btn1: "Let's Create",
  btn2: "Contact Us",
};

export const WORKS = [
  {
    heading: "24/7 Availability",
    text: "GBOT can interact with your customers round the clock, providing instant responses to inquiries, thus enhancing customer service and satisfaction.",
    icon: <AddReaction />,
  },
  {
    heading: "Cost Savings",
    text: "By automating repetitive tasks and reducing the need for human intervention in customer support, GBOT can help businesses save on operational costs in the long run.",
    icon: <AddReaction />,
  },
  {
    heading: "Customer Engagement",
    text: "By engaging customers in real-time conversations, GBOT can keep users interested and involved, leading to increased interaction and brand loyalty.",
    icon: <AddReaction />,
  },
  {
    heading: "Lead Generation",
    text: "GBOT can qualify leads by asking relevant questions and collecting essential information from potential customers, thus streamlining the sales process and increasing conversion rates.",
    icon: <AddReaction />,
  },
  {
    heading: "Personalized Interactions",
    text: "With access to customer data and preferences, GBOT can deliver personalized recommendations, promotions, and content, enhancing the overall customer experience",
    icon: <AddReaction />,
  },
  {
    heading: "Efficient Customer Support",
    text: "GBOT can handle a significant portion of customer support queries, resolving common issues quickly and freeing up human agents to focus on more complex tasks",
    icon: <AddReaction />,
  },
  {
    heading: "Data Collection and Analysis",
    text: "GBOT can gather valuable insights from customer interactions, such as frequently asked questions, common pain points, and customer feedback, which bus",
    icon: <AddReaction />,
  },
  {
    heading: "Enhanced Marketing Campaigns",
    text: "GBOT can be integrated into marketing campaigns to deliver targeted messages, gather feedback, and provide personalized recommendations, thereby increasing campaign effectiveness and ROI.",
    icon: <AddReaction />,
  },
  {
    heading: "Made Just for You",
    text: "We create chatbots that are unique to your business. No cookie-cutter solutions here!",
    icon: <AddReaction />,
  },
];

export const COUNTER = [
  {
    title: "Clients",
    value: "35",
  },
  {
    title: "BOT",
    value: "105",
  },
  {
    title: "Chats",
    value: "12000",
  },
  {
    title: "Users",
    value: "3500",
  },
];

export const TemplateData = [
  {
    heading: "Healthcare Chatbot",
    url: "chat/WcCw2L3X2WtohwdVemjRw==",
    img: "HealthCare.webp",
    discription:
      "Healthcare AI Chatbot for appointment scheduling, telemedicine, preventive care, lab test, Insurance, andfeedback collection. Provide seamless patient experience.",
  },
  {
    heading: "Travel Chatbot",
    url: "chat/1ks49GIfeFRaHBbHyh06Jw==",
    img: "Travel.webp",
    discription:
      "Introducing our Travel Companion Chatbot - your ultimate travel buddy! Whether you're planning a weekend getaway or a globetrotting adventure, our chatbot is here to make your journey seamless and unforgettable.",
  },
  {
    heading: "Feedback Chatbot",
    url: "chat/jEtxQmtacZGagtk6w0CTZA==",
    img: "Feedback.webp",
    discription:
      "Introducing our Feedback Assistant Chatbot - your virtual feedback concierge! Whether you're a customer with valuable insights or a business seeking to improve, our chatbot is here to streamline the feedback process.",
  }
  // ,
  // {
  //   heading: "Lead Collection Chatbot",
  //   url: "chat/jEtxQmtacZGagtk6w0CTZA==",
  //   img: "leadCollection.jpg",
  //   discription:
  //     "Generating leads is one of the most important goals for any business. Lead collection chatbot can replace boring and cluttered forms with conversational and interactive interfaces.",
  // },
  // {
  //   heading: "Meeting Booking Chatbot",
  //   url: "chat/jEtxQmtacZGagtk6w0CTZA==",
  //   img: "meetingBooking.jpg",
  //   discription:
  //     "The meeting booking chatbot helps your users quickly book meetings with your team. It can schedule, reschedule appointments within minutes, which can save time.",
  // },
  // {
  //   heading: "Education Chatbot",
  //   url: "chat/jEtxQmtacZGagtk6w0CTZA==",
  //   img: "education.jpg",
  //   discription:
  //     "For any college, university, or e-learning platform, swift communication is crucial for converting prospective applicants into enrolled students and supporting existing students, faculty, and staff.",
  // },
  // {
  //   heading: "Customer Support",
  //   url: "chat/jEtxQmtacZGagtk6w0CTZA==",
  //   img: "customerSupport.jpg",
  //   discription:
  //     "Customer Support Chatbot let's your customer easily navigate through your products and pricing page and help them setup the demo meeting or chat with your Agent.",
  // },
  // {
  //   heading: "Job Portal",
  //   url: "chat/jEtxQmtacZGagtk6w0CTZA==",
  //   img: "jobPortal.jpg",
  //   discription:
  //     "The chatbot provides a user-friendly interface for job seekers to navigate through available job listings, get detailed information about each role, and submit applications effortlessly. Additionally, it assists recruiters by facilitating candidate screening and interview scheduling.",
  // },
];

export const HowItWorksData = [
  {
    img: "ttu.webp",
    heading: "Talk to us",
    discription:
      "Talk to us where we can understand your requirement & questionnaires to create a customized Bot for your business and you can select you theme.",
  },
  {
    img: "cfu.webp",
    heading: "Create for you",
    discription:
      "Our team create a bot as per your requirement and We publish a URL where you can test it and suggest if any other changes required. We will incorporate those changes and deploy it.",
  },
  {
    img: "ui.webp",
    heading: "Use it",
    discription:
      " Your Chatbot is ready to go! We help you how to integrate with your existing website (if any) otherwise you can directly use our sub-domain URL.",
  },
];

export const aboutBot = [
  {
    name: "Experience:",
    contant:
      "With years of experience in the chatbot industry, our team of experts is dedicated to delivering high-quality solutions that meet the unique needs of each business.",
  },
  {
    name: "Scalability:",
    contant:
      "Whether you're a small startup or a large enterprise, our chatbots are designed to scale with your business, ensuring seamless growth and adaptation to evolving needs.",
  },
  {
    name: "Customer-Centric Approach:",
    contant:
      "We prioritize customer satisfaction and are committed to providing exceptional support and service to our clients, building long-term partnerships based on trust and reliability.",
  },
  {
    name: "24/7 Availability:",
    contant:
      "With our chatbots handling inquiries round-the-clock, businesses can provide instant support and assistance to their customers anytime, anywhere, improving customer satisfaction and loyalty.",
  },
  {
    name: "Analytics Dashboard:",
    contant:
      "Gain valuable insights into user interactions, trends, and customer feedback with our comprehensive analytics dashboard, empowering businesses to make informed decisions and optimize their chatbot strategy.",
  },
  {
    name: "Customization:",
    contant:
      " We understand that every business has unique requirements, which is why our chatbots are highly customizable. From branding and language to response templates and workflows, we tailor our solutions to align with your specific needs and preferences.",
  },
];

export const MenuOptions = ["Edit", "Archieve"];

export const botType = ["PLUGIN", "WEB PAGE"]