
import { AppBar, Divider, Grid, IconButton, Paper, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Test from "../../../../assets/data/Test";
import BotService from "../../../../service/BotService";
import BasicDetails from "./Add/BasicDetails";
import MailSetting from "./Add/MailSetting";
import AddQuestion from './Add/Questions/AddQuestion';
import ThemeSetting from "./Add/ThemeSetting";
import './botStyle.css';
import logo from '../../../../assets/images/web/GBot_large.png'
import { DoneOutline } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";

export default function BotConfiguration() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const [createdBot, setCreateBot] = useState('');
    const [botName, setBotName] = useState('Bot Name');
    const [chatBotLogo, setChatBotLogo] = useState('');
    const [themeSettings, setThemeSettings] = useState(
        {
            hoverTextColor: "#20bcc7",
            textColor: "white",
            backgroundColor: "white",
            borderColor: "white",
            hoverBackgroundColor: "",
            correctButtonColor: "#3aca9b",
            resetButtonColor: "red",
            scrollBarColor: "#b6d6de",
            scrollBarHoverColor: "#20bcc7",
            botBackgroundColor: "#102e313b",
            themeColor: "#21acd7",
            backgroundPath: "",
            logoPath: "",
        }
    )

    useEffect(() => {
        if (themeSettings.logoPath) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setChatBotLogo(reader.result);
            };
            reader.readAsDataURL(themeSettings.logoPath);
        }
    }, [themeSettings.logoPath]);

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const saveBotDetails = async (data) => {
        try {
            const response = await BotService.createNewBot(data);
            if (response) {
                if (response.status === 200) {
                    setCreateBot(response.data)
                    handleChange(1);
                } else {
                    toast(response.data);
                }
            }
        } catch (error) {
            console.error("Error saving data:", error);
        }
    }

    const saveThemeDetails = async () => {
        try {
            const formData = new FormData();
            for (const key in themeSettings) {
                formData.append(key, themeSettings[key]);
            }
            formData.append("botId", createdBot.id);
            const response = await BotService.addThemeSettingToBot(formData)
            if (response) {
                if (response.status === 200) {
                    navigate("/dashboard")
                    toast("Your bot created successfully.")
                } else {
                    toast.warn("Something went wrong!")
                }
            }
        } catch (error) {
            console.error("Error saving data:", error);
        }
    }

    const renderTab = () => {
        switch (value) {
            case 0:
                return <BasicDetails setBotName={setBotName} saveBotDetails={saveBotDetails} />;
            case 1:
                return <ThemeSetting themeSettings={themeSettings} setThemeSettings={setThemeSettings} saveThemeDetails={saveThemeDetails} />;
            case 2:
                return <AddQuestion handleChange={handleChange} />;
            case 3:
                return <MailSetting handleChange={handleChange} />
            default:
                return <div></div>
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item md={12} className="panel-head-grid">
                <h3>Bot Configuration</h3>
                <Divider />
            </Grid>
            <Grid item md={12}>
                <Paper style={{ padding: '20px' }}>
                    <AppBar position="static">
                        <Tabs
                            value={value}
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >

                            <Tab label="Basic Details" disabled={value === 0 ? false : true} />
                            <Tab label="Theme Setting" disabled={value === 1 ? false : true} />
                            {/* <Tab label="Questions" disabled={value === 2 ? false : true} /> */}
                            {/* <Tab label="Mail Setting" disabled={value === 3 ? false : true} /> */}
                        </Tabs>
                    </AppBar>
                    <Grid container spacing={2} >
                        <Grid item md={8} lg={9}>
                            <Paper style={{ marginTop: '3.7vh', padding: '2vh' }}>
                                {renderTab()}
                            </Paper>
                        </Grid>
                        <Grid item md={4} lg={3}>
                            <Paper style={{ marginTop: '3.7vh' }}>
                                <div className="chat-box-preview" style={{ backgroundColor: `${themeSettings.botBackgroundColor}` }}  >
                                    {<div className='chatBot-header' style={{ backgroundColor: `${themeSettings.themeColor}` }} >
                                        <img src={chatBotLogo === '' ? logo : chatBotLogo} alt='' ></img>
                                        <p id='chatBot-header-name'>{botName}</p>
                                    </div>}
                                    <div className="chat-preview">
                                        <div className='chat-box-preview-content' >
                                            <div className='content-box' style={{ paddingBottom: "1vh" }}>
                                                {Test && Test.map((m, index) =>
                                                    <div key={index}>
                                                        {m.msg.map((item, itemIndex) =>
                                                        (item.msg === null ? '' :
                                                            <div className='chat-que2' key={itemIndex}>
                                                                <div> {item.msg}</div>
                                                            </div>)
                                                        )}
                                                        {(m.ans.msg === null || m.ans.msg === '') ? '' :
                                                            <div className='chat-ans'
                                                                style={{
                                                                    whiteSpace: 'pre-line',
                                                                    backgroundColor: `${themeSettings.themeColor}`,
                                                                    color: `${themeSettings.textColor}`
                                                                }}>
                                                                {m.ans.msg.replace(/#\$/g, '\n')}
                                                            </div>
                                                        }
                                                    </div>
                                                )}
                                                <div className='cb-proceed' style={{ gap: "1vh" }}>
                                                    <IconButton sx={{
                                                        backgroundColor: `${themeSettings.correctButtonColor}`
                                                    }}>
                                                        <DoneOutline />
                                                    </IconButton>
                                                    <IconButton sx={{
                                                        backgroundColor: `${themeSettings.resetButtonColor}`
                                                    }}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}