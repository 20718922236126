import { environment } from "./environments/environment";

export const config = { apiUrl: environment.URL };

export const LINK = {
  HOME: "/dashboard",
  INDUSTRY: "/industry",
  LANGUAGE: "/language",
  SUBSCRIPTION: "/subscription",
  CLIENT: "/client",
  BOTS: "/bots",
  BOTS_BY_CLIENT: "bots-by-client",
  ADD_BOTS: "/bots/add-new",
  BILLING: "/billing",
  SETTING: "/setting",
  DOCUMENTS: "/documents",
  TEMPLATES: "/templates",
  QUESTION: "/question",
  PROFILE: "/profile",
  REPORT: "/report",
  SIGN_IN: "/login",
  SIGN_UP: "/registration",
  OTP: "/otp-verification",
  LOGOUT: "/",
  ADD_QUESTIONS: "/add-questions",
  BOT: "my-bot",
  USER_QUERY: "/report/user-query"
};

export const API_ROUTES = {
  AUTHENTICATION: config.apiUrl + 'auth',
  SIGN_IN: config.apiUrl + 'auth/login',
  REFRESH_TOKEN: config.apiUrl + 'auth/refreshToken',
  PLACE_ENQUIRY: config.apiUrl + 'place-enquiry',
  GET_ENQUIRY: config.apiUrl + 'get-enquiry',
  UPDATE_VISITOR: config.apiUrl + 'add-visitor',
  GET_BOT: config.apiUrl + 'endPoint',
  INDUSTRY: config.apiUrl + 'industry',
  LANGUAGE: config.apiUrl + 'language',
  CLIENT: config.apiUrl + 'client',
  SUBSCRIPTION: config.apiUrl + 'subscription',
  ADD_QUESTION: config.apiUrl + 'que',
  BOT: config.apiUrl,
  BOT_BY_CLIENT: config.apiUrl + 'get-bots',
  BILLING: config.apiUrl + 'billing',
  ACTIVATE_BOT: config.apiUrl + 'activate'
};

export const ROLE = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  CLIENT: 'CLIENT'
}