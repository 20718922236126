
import { DoneOutline } from '@mui/icons-material';
import { TextField } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";

export default function ShortText({ props, increaseSQWithDelay }) {

    const [error, setError] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const handleClick = (event) => {
        const inputValue = event.currentTarget.closest('.MuiFormControl-root').querySelector('input').value;

        if (props.isSkipped && inputValue.trim() === '') {
            increaseSQWithDelay(props, inputValue, null);
        }

        if (inputValue.trim() !== '') {
            increaseSQWithDelay(props, inputValue, null);
        } else {
            setError(true);
        }
    }

    return <TextField
        key={props.sq}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        label={props.lable}
        variant="outlined"
        helperText={error ? <span style={{ color: 'red', fontWeight: '900' }}>Required*</span> : ""}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        onClick={handleClick}
                        className='custom-textfield-icon-btn'>
                        <DoneOutline />
                    </IconButton>
                </InputAdornment>
            ),
        }}
        InputLabelProps={{
            shrink: true,
        }}
        className="custom-textfield"
    />
}