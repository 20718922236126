
import  AddQuestionDetails  from "../component/admin/model/AddQuestionDetails";
import { API_ROUTES } from "../utils/apiConfig";
import axiosInstance from "./axiosInstance";

export default class QuestionService {
  static async addQuestions(data: AddQuestionDetails) {
    try {
      const response = await axiosInstance.post(API_ROUTES.ADD_QUESTION, data);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }
}
