import React from "react";
import LongText from "./answer_type/LongText";
import ShortText from "./answer_type/ShortText";
import YesNo from "./answer_type/YesNo"
import AutoComplete from "./answer_type/AutoComplete";
import Button from "./answer_type/Button";
import Calendar from "./answer_type/Calendar";
import DateRange from "./answer_type/DateRange";
import FilePicker from "./answer_type/FilePicker";
import Language from "./answer_type/Language";
import Location from "./answer_type/Location";
import MultipleChoice from "./answer_type/MultipleChoice";
import NumberInput from "./answer_type/NumberInput";
import NumberRange from "./answer_type/NumberRange";
import Password from "./answer_type/Password";
import Rating from "./answer_type/Rating";
import Spinner from "./answer_type/Spinner";
import TimePicker from "./answer_type/TimePicker";
import Default from "./answer_type/Default";

interface Components {
    [key: number]: React.ElementType;
  }

const Answer = (props: any) => {
    const components:Components = {
        0: Default,
        1: ShortText,
        2: LongText,
        3: YesNo,
        4: Rating,
        5: MultipleChoice,
        6: AutoComplete,
        7: Spinner,
        8: NumberInput,
        9: Location,
        10: Calendar,
        11: TimePicker,
        12: DateRange,
        13: NumberRange,
        14: FilePicker,
        15: Button,
        16: Password,
        17: Language
        // Add other components here...
      }
      const id = (props.typeId == null) ? 0 :  props.typeId; 
      const ComponentToRender: any = components[id];
    return(
        <ComponentToRender />
    );
}

export default Answer;