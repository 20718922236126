import {FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { useState } from "react";
import './Style.css';
import Answer from "../Answer";

const Rating = () => {
    const [rating, setRating] = React.useState('1');
    const [value, setValue] = useState('5');

  const selectChange = (event: SelectChangeEvent) => {
    setRating(event.target.value);
  };

  const handleChange = (event:any) => {
    const inputValue = event.target.value;
    if (inputValue >= 1 && inputValue <= 10) {
      setValue(inputValue);
    } else {
        setValue('');
    }
  };
    return(
        <>
        <Grid container>
        <Grid item md={2}></Grid>
          <Grid item md={4}>
          <FormControl sx={{ m: 1}} size="small" style={{ width: '90%' }}>
            <InputLabel id="demo-select-small-label">Rating</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={rating}
                label="Rating"
                onChange={selectChange}
            >
                <MenuItem value={1}>Star</MenuItem>
                <MenuItem value={2}>Circle</MenuItem>
                <MenuItem value={3}>Smily</MenuItem>
            </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} sx={{ padding: '8px' }}>
            <TextField
                id="rating_length"
                label="Rating Length"
                fullWidth 
                size="small"
                type="number"
                value={value}
                onChange={handleChange}
                InputProps={{
                    inputProps: { min: 1, max: 10 },
                  }}
            />
          </Grid>
        </Grid>
        </>
    );
}

export default Rating;