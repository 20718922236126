
import React, { useState as ReactUseState } from 'react'; // Import useState from React and rename it
import { Grid, Paper, Divider, Button, TextField } from "@mui/material";
import {TextFields, Image, Link, CloudUpload, Close } from '@mui/icons-material';

const MCOption = (props: any) => {
    const key = props.id;
    const [activeButton, setActiveButton] = ReactUseState<Number | null>(1);
    const icons = [<TextFields />, <Image />, <Link />];
    const [activeGrid, setActiveGrid] = ReactUseState<Number | null>(1);
    const handleButtonClick = (buttonIndex: Number) => {
        setActiveButton(buttonIndex);
        setActiveGrid(buttonIndex);
    };

    const handleFileInputChange = (event: any) => {
        const file = event.target.files[0];
    };

    const removeOptions = (key: any) => {
        props.removeOption(key);
    }

    return(
        <Grid container>
          <Grid item md={12}>
            <Paper className='ans-pan mc-option-grid'>
              <p className='que-head'>Option {props.id}
                <span className='mc-option-close'><Close onClick={() => removeOptions(key)}/></span>
              </p>
              <Divider />
              <Grid container>
                <Grid item md={8} sx={{ padding: '8px' }}>
                    {[1, 2, 3].map((buttonIndex) => (
                        <div className="choice-btn">
                            <Button
                            key={buttonIndex}
                            variant={activeButton === buttonIndex ? 'contained' : 'outlined'}
                            onClick={() => handleButtonClick(buttonIndex)}
                            startIcon={icons[buttonIndex - 1]}
                            >
                            </Button>
                        </div>
                    ))}
                </Grid>
                {activeGrid === 1 && (
                <Grid item md={6} sx={{ padding: '8px' }}>
                    <TextField
                        id="option_1"
                        label="Add Option"
                        fullWidth 
                        size="small"
                />
                </Grid>
                )}
                {activeGrid === 2 && (
                <Grid item md={6} sx={{ padding: '8px' }}>
                    <input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        
                        onChange={handleFileInputChange}
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="outlined" component="span"startIcon={<CloudUpload />} fullWidth>
                        Upload Image
                        </Button>
                    </label>
                </Grid>
                 )}
                 {activeGrid === 3 && (
                <Grid item md={6} sx={{ padding: '8px' }}>
                    <TextField
                        id="link"
                        label="Enter Link"
                        fullWidth 
                        size="small"
                        
                />
                </Grid>
                 )}
            </Grid>
            
            </Paper>
          </Grid>
        </Grid>
    );
}

export default MCOption;

function useState<T>(arg0: number): [any, any] {
    throw new Error("Function not implemented.");
}
