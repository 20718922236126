import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import dayjs from 'dayjs';

function Answer(props) {
    const { data } = props;

    const renderAnswer = () => {
        switch (data.ansType) {
            case 'Swiper':
                return (
                    <Card sx={{ maxWidth: 200, marginLeft: "auto", marginBottom: "4%" }}>
                        <CardActionArea>
                            {data.ans.data.img &&
                                <CardMedia
                                    component="img"
                                    height="100"
                                    image={data.ans.data.img}
                                    alt="green iguana"
                                />
                            }
                            <CardContent className='card-content selected'>
                                <Typography gutterBottom variant="h6" component="div">
                                    {data.ans.data.msg1}
                                </Typography>
                                <Typography variant="body2">
                                    {data.ans.data.msg2}
                                </Typography>
                                <Typography variant="body2">
                                    {/* &#8377;  */}
                                    {data.ans.data.msg3}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                );
            case 'City':
                return (
                    <div className='chat-ans'> {data.ans.msg} </div>
                );
            case 'DateRange':
                return <div className='chat-ans'>
                    {"Start Date: " + dayjs(data.ans.msg.startDate).format('DD-MM-YYYY')}
                    <br />
                    {"End Date: " + dayjs(data.ans.msg.endDate).format('DD-MM-YYYY')}
                </div>
            case 'FilePicker':
                return <div className='chat-ans'> {data.ans.msg} </div>
            case 'Rating':
                return (
                    <div className='star-print'>  {[...Array(Math.min(data.ans.msg))].map((_, index) => {
                        const starNumber = index + 1;
                        return (
                            <span key={starNumber}>
                                ★
                            </span>
                        );
                    })} </div>
                );
            case 'Number':
            case 'DateTimePicker':
            case 'Mobile':
            case 'DatePicker':
            case 'Email':
            case 'LongText':
                return (
                    <div className='chat-ans' style={{ whiteSpace: 'pre-line' }}> {data.ans.msg.replace(/#\$/g, '\n')} </div>
                )
            default:
                return Array.isArray(data.ans) ? (
                    <div className='chat-ans'>
                        {data.ans.map((answer, ansIndex) => (
                            <div key={ansIndex}> {answer.msg}</div>
                        ))}
                    </div>
                ) : (
                    <div className='chat-ans'> {data.ans.msg} </div>
                );
        }
    };

    return (
        <>
            {renderAnswer()}
        </>
    );
}

export default Answer;
