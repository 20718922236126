import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

export default function TickPlacementBars({ dataset , label, dataKey}) {

  const chartSetting = {
    series: [{ dataKey: 'data', label: label }],
    height: 400,
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: 'translateX(-10px)',
      },
    },
  };

  return (
    <div style={{ width: '100%' }}>
      <BarChart
        dataset={dataset}
        xAxis={[
          { scaleType: 'band', dataKey: dataKey },
        ]}
        {...chartSetting}
      />
    </div>
  );
}
