import axiosInstance from "../../../service/axiosInstance";
import { API_ROUTES } from "../../../utils/apiConfig";

export default class BillingService {
  static async subscribeForClient(data: any) {
    try {
      const response = await axiosInstance.post(
        `${API_ROUTES.BILLING}/add-client-subscription`,
        data
      );
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  static async getClientCurrentBilling(data: any) {
    try {
      const response = await axiosInstance.get(
        `${API_ROUTES.BILLING}/current-bill?billingId=${data}`
      );
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  static async getClientAllBillings(data: any) {
    try {
      const response = await axiosInstance.get(
        `${API_ROUTES.BILLING}/client-id?clientId=${data}`
      );
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  static async createOrder(price: any) {
    try {
      const response = await axiosInstance.post(
        `${API_ROUTES.BILLING}/create-order?price=${price}`
      );
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  static async getClientAllBillingsByEmailId(emailId: any) {
    try {
      const response = await axiosInstance.get(
        `${API_ROUTES.BILLING}/email-id?emailId=${emailId}`
      );
      return response;
    } catch (error: any) {
      return error.response;
    }
  }
}
