import { CancelPresentation } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, TextField } from "@mui/material";
import { toast } from "react-toastify";
import ClientService from "../../services/ClientService";
import './style.css';

export default function AddClient(props: any){

  const saveClient = async (event: React.FormEvent<HTMLFormElement> | any) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
        const name = formData.get("name") as string;
        const contactNo = formData.get("contact") as string;
        const emailId = formData.get("email") as string;
        const city = formData.get("city") as string;
        const address = formData.get("address") as string;
        const state = formData.get("state") as string;
        const country = formData.get("country") as string;

        if (!name.trim()) {
          toast.error("Please enter a name for the Client.");
          return;
        }
        if (!emailId.trim()) {
          toast.error("Please enter a email for the Client.");
          return;
        }
      const data = {
          name,
          contactNo,
          emailId,
          city,
          address,
          state,
          country
      }
     
    try {
      const response = await ClientService.saveClient(data);
      if (response.status === 200) {
        props.getData();
        props.onClose()
      }
      response.status === 200
        ? toast.success(response.data)
        : toast.error(response.data);
    } catch (error) {
      toast.error(emailId + " this email id already registered with diffrent client please try with another");
    }
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        component="form"
        onSubmit={saveClient}
      >
        <DialogTitle><h3>Add New Client
        <IconButton className="client-dailog-close" onClick={props.onClose}>
          <CancelPresentation />
          </IconButton> </h3>
          <Divider />
        </DialogTitle>
        <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                    margin="normal"
                    fullWidth
                    id="name"
                    label="Client Name"
                    name="name"
                />
              </Grid>
              <Grid item md={6}>
              <TextField
                  margin="normal"
                  fullWidth
                  id="contact"
                  label="Contact No"
                  name="contact"
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Id"
                    name="email"
                />
              </Grid>
              
              <Grid item md={6}>
                <TextField
                    margin="normal"
                    fullWidth
                    id="city"
                    label="City"
                    name="city"
                />
              </Grid>
              <Grid item sm={12} md={12}>
              <TextField
                    margin="normal"
                    fullWidth
                    id="address"
                    label="Address"
                    name="address"
                    multiline
                    rows={4}
                />
              </Grid>
              <Grid item md={6}>
              <TextField
                    margin="normal"
                    fullWidth
                    id="state"
                    label="State"
                    name="state"
                />
              </Grid>
              <Grid item md={6}>
              <TextField
                    margin="normal"
                    fullWidth
                    id="country"
                    label="Country"
                    name="country"
                />
              </Grid>
              <Grid item md={12}>
                <Button type="submit" variant="contained">Submit</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          
        </DialogActions>
      </Dialog>
    </>
  );
}