import { DoneOutline } from '@mui/icons-material';
import { TextField } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from 'react';

export default function Email({ props, increaseSQWithDelay }) {
    const [error, setError] = useState(false);

    const handleButtonClick = (event) => {
        const inputValue = event.currentTarget.closest('.MuiFormControl-root').querySelector('input').value;
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue);

        if (inputValue === '' && props.isSkipped) {
            increaseSQWithDelay(props, inputValue, null);
        }

        if (isValidEmail) {
            increaseSQWithDelay(props, inputValue, null);
            setError(false);
        } else {
            setError(true);
        }
    };

    return (
        <div className='outer-email-div' key={props.sq}>
            <TextField
                label="Email"
                variant="outlined"
                error={error}
                helperText={error ? <span style={{ color: 'red', fontWeight: '900' }}>Invalid email format</span> : ""}
                className="custom-textfield"
                InputProps={{
                    type: 'email',
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleButtonClick}
                                className='custom-textfield-icon-btn'
                            >
                                <DoneOutline />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
}
