import { Button, Divider, Grid, Paper, Switch } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BotTable } from "../../../../assets/data/tableConfig";
import BotService from "../../../../service/BotService";
import ConfirmationDialog from "../../../../utils/ConfirmationDialog";
import { LINK, ROLE } from "../../../../utils/apiConfig";

export default function Bot() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const role = JSON.parse(localStorage.getItem("currentUser") || "{}").role;
  const clientId = JSON.parse(localStorage.getItem("currentUser") || "{}").email;

  const columns = BotTable.map((column) => ({
    ...column,
    ...(column.field === "action" && {
      renderCell: (params) => getRenderCell(params.row),
    }),
  }));

  useEffect(() => {
    // debugger
    const fetchData = async () => {
      const urlSegments = window.location.href.split("/");
      const lastSegment = urlSegments[urlSegments.length - 1];

      try {
        let response;
        if (lastSegment === LINK.BOTS_BY_CLIENT) {
          response = await BotService.getAllBotByClientEmailId(
            clientId
          );
        } else {
          response = await BotService.getAllBot();
        }

        if (response) {
          if (response.status === 200) {
            console.log(response.data);
            const formattedData = response.data.map((bot) => ({
              ...bot,
              active: Boolean(bot.active),
            }));
            setRows(formattedData);
          } else {
            toast(response.data);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const getRenderCell = (rowData) => {
    return (
      <div className="action-btn">
        <div className="switch">
          <Switch
            checked={rowData.active}
            onChange={() => handleDailogOpen(rowData)}
            color="warning"
          />
        </div>
      </div>
    );
  };

  const handleSwitchChange = async (rowData) => {
    toast.success("Status Updated Successfully!");
  };

  const handleDailogOpen = (rowData) => {
    if (role === ROLE.SUPER_ADMIN) {
      setRowData(rowData);
      setOpen(true);
    }
  };
  const handleDailogClose = () => {
    setOpen(false);
  };
  const handleDailogConfirm = () => {
    handleSwitchChange(rowData);
    setOpen(false);
    setRowData(null);
  };
  const navigate = useNavigate();
  const addBot = () => {
    navigate("/bots/add-new");
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} className="panel-head-grid">
        <div className="panel-head-div">
          <h3>Bot Details</h3>
          <Button type="submit" variant="contained" onClick={addBot}>
            Add New Bot
          </Button>
        </div>
        <Divider />
      </Grid>

      <Grid item md={12}>
        <Paper style={{ padding: "20px", maxHeight: 580 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 100]}
          />
        </Paper>
      </Grid>
      <ConfirmationDialog
        open={open}
        onClose={handleDailogClose}
        onConfirm={handleDailogConfirm}
        title="Confirmation"
        message="Are you sure you want to Proceed?"
      />
    </Grid>
  );
}
