import { Cancel } from '@mui/icons-material';
import { Grid } from '@mui/material';

export default function BotButton({
  showChatBox,
  closeChatBox,
  img,
  isHovered,
  setIsHovered }) {

  return <>
    <Grid container spacing={2}>
      {/* <div
        className={`bot-container ${isHovered ? 'show' : ''}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <p className='bot-text'>Can i help you ? </p>
        <Button variant="outlined" className='bot-btn' onClick={showChatBox}>Let's Chat</Button>
      </div>
      <div className={`bottom-left-div ${isHovered ? 'hide' : ''} ${!img ? 'bottom-left-div-after' : ''}`}>
        {!img && <Cancel className='close'
          onClick={closeChatBox}
        />}
      </div>
      {img && <img src={require('../../assets/images/bot.png')}
        className='img'
        alt='img'
        onMouseEnter={() => setIsHovered(true)}
      />
      } */}

      
    <div className="bottom-left-chat">
      {!img && <Cancel className='close'
          onClick={closeChatBox}
        />}
        
      {img && <img src={require('../../assets/images/web/GBot_large.png')}
        className='chat-btn'
        alt='img'
        onClick={showChatBox}
      />}
    </div>
    </Grid>

  </>
}
