import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Chip, Grid, Paper, Switch } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from 'react';
import { MenuOptions, botType } from '../../../assets/data/WebContent';
import { ROLE } from '../../../utils/apiConfig';
import { environment } from '../../../utils/environments/environment';

const label = { inputProps: { "aria-label": "Switch demo" } };

export default function MobileView({
  role,
  rows,
  handleSwitch,
  handleReportClick
}) {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const handleMenuClick = (menu) => {
    setAnchorEl(null);
  };

  const previlege = (verified) => {
    if (role === ROLE.SUPER_ADMIN) {
      return true;

    } else if (role === ROLE.CLIENT) {
      if (verified) {
        return true;
      } else {
        return false;
      }
    }

    return false
  }

  const handleUrlClick = (item) => {
    window.open('/chat/' + item.endPoint)
  }

  return (
    <div className="mobile-container">
      <Grid container spacing={2} >
        {rows.map((item, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}>
            <Paper elevation={3} key={index} sx={{ padding: "7px" }}>
              <Card sx={{ maxWidth: "-webkit-fill-available" }} key={index}>
                <CardHeader
                  action={previlege(item.verified) ?
                    <IconButton aria-label="add to favorites" onClick={() => handleSwitch(item)} disabled={item.verified}>
                      <Switch {...label} checked={item.verified} disabled={item.verified} />
                    </IconButton> : " "
                  }
                  title={item.name}
                />
                <CardMedia
                  component="img"
                  height="194"
                  // image='https://bot.gateway2it.com/image/garrett-parker-DlkF4-dbCOU-unsplash.jpg'
                  image={environment.IMAGE_BASE_URL + item.themeSetting.backgroundPath}

                  // image={require(`D:/Saurabh/mars/workspace/bot/bot-backend/bot-App/src/main/webapp/images/gbot/background/${item.themeSetting.backgroundPath}`)} 
                  alt="Paella dish"
                />
                <CardContent>
                  <div className="inner-right-content-mobile">
                    <div className="right-content-items-mobile">
                      Completed <span>{item.usedChat}</span>
                    </div>
                    <div className="right-content-items-mobile">
                      Opens <span>{item.noOfVisitor}</span>
                    </div>
                    <div className="right-content-items-mobile">
                      Time to Finish <span>{item.timeToFinish}</span>
                    </div>
                  </div>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: "space-around", padding: 0.5 }}>
                  {item.type === botType[1] &&
                    <IconButton aria-label="add to favorites" onClick={() => handleUrlClick(item)} >
                      <OpenInNewIcon />
                    </IconButton>
                  }

                  <IconButton aria-label="share">
                    <div className="right-content-items">
                      <Chip
                        sx={{ padding: 2 }}
                        label="Report"
                        variant="outlined"
                        onClick={() => handleReportClick(item)}
                      />
                    </div>
                  </IconButton>
                  <IconButton aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={(event) => handleClick(event, item)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    {MenuOptions.map((option, itemIndex) => (
                      <MenuItem
                        key={itemIndex}
                        onClick={() => handleMenuClick(option)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </CardActions>
              </Card>
            </Paper>
          </Grid>

        ))}
      </Grid>
    </div>
  );
}
