import { CloudUpload, DoneOutline } from '@mui/icons-material';
import { Button } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from 'react';

export default function FilePicker({ props, increaseSQWithDelay }) {

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    return (
        <Grid item md={6} sx={{ padding: '8px' }}>
            <input
                accept="image/*,.pdf"
                id="contained-button-file"
                type="file"
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
            />
            <label htmlFor="contained-button-file">
                <Button variant="outlined" component="span" startIcon={<CloudUpload />} fullWidth>
                    {selectedFile ? selectedFile.name : 'Upload File'}
                </Button>
            </label>
            {
                <div className='cb-proceed-Swiper'>
                    <IconButton className={selectedFile === null ? 'not-selected' : 'cb-ok'}
                        onClick={() => increaseSQWithDelay(props,selectedFile.name, selectedFile)} sx={{ left: '23%' }}
                        disabled={!selectedFile}>
                        <DoneOutline />
                    </IconButton>
                </div>
            }
        </Grid>
    );
}
