import { AccountBalanceWallet, Dashboard, IntegrationInstructions, Logout, MarkEmailUnread, Security, Settings } from "@mui/icons-material";
import { LINK } from "../../utils/apiConfig";

export const CLIENTMENU = [
  {
    menu: "Dashboard",
    link: LINK.HOME,
    icon: <Dashboard />
  },
  {
    menu: "Bots",
    link: "/" + LINK.BOTS_BY_CLIENT,
    icon: <Security />
  }, 
  {
    menu: "User Queries",
    link: LINK.USER_QUERY,
    icon: <MarkEmailUnread />
  },
  {
    menu: "Billing",
    link: LINK.BILLING,
    icon: <AccountBalanceWallet />
  },
  // {
  //   menu: "Setting",
  //   link: LINK.SETTING,
  //   icon: <Settings />
  // },
  {
    menu: "Documents",
    link: LINK.DOCUMENTS,
    icon: <IntegrationInstructions />
  },
  // {
  //   menu: "Templates",
  //   link: LINK.TEMPLATES,
  //   icon: <Security />
  // },
  // {
  //   menu: "Question",
  //   link: LINK.QUESTION,
  //   icon: <Security />
  // },
  {
    menu: "Logout",
    link: LINK.LOGOUT,
    icon: <Logout />
  },
];

