import React from "react";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';

const Tooltips = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      maxWidth: '200px',
    },
  }));

const QueTooltip = (props: any) => {
    return(
        <>
        <Tooltips placement="right" title={<React.Fragment>
            <Typography variant="body1">
                <span className="tooltip-text">{props.title}</span>
            </Typography>
        </React.Fragment>}
            TransitionComponent={Zoom}>
                <div className='option'>
                    {props.icon}
                    <div><span>{props.btnText}</span></div>
                </div>
        </Tooltips>
        </>
    );
}

export default QueTooltip;