import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import chatBotHeaderLogo from '../assets/images/GBOT.png';

function Header() {
  const navigate = useNavigate();
  const login = () => {
    navigate("/login");
  };

  return (
    <Box sx={{ flexGrow: 1 }} className='bot-top-header'>
      <AppBar position="static" >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img src={chatBotHeaderLogo} alt='' className='header-logo'></img>
          </Typography>
          <Button color="inherit" onClick={login}>Login</Button>
        </Toolbar>
      </AppBar>
    </Box>

  );
}

export default Header;