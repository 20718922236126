import { Button, Divider, Grid, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ClientData from "../../model/ClientData";
import ClientService from "../../services/ClientService";

export default function Profile(){

    const [profile, setProfile]  = useState<ClientData>({} as ClientData);

    useEffect(() => {
        getClientDetailsByEmail();
      }, []);
    
      const getClientDetailsByEmail = async() =>{
        try {
            const currentUser: any = JSON.parse(localStorage.getItem("currentUser") || "{}").email;
            const response = await ClientService.getClientDetailsByUserName(currentUser)
            if (response.status === 200) {
                setProfile(response.data)
            }else{
                toast.error(response.data);
            }
          } catch (error) {
           toast.error("Problem to get your profile details");
          }
        };
      
      
    const updateClient = async (event: React.FormEvent<HTMLFormElement> | any) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
            const name = formData.get("name") as string;
            const contactNo = formData.get("contact") as string;
            const emailId = formData.get("email") as string;
            const city = formData.get("city") as string;
            const address = formData.get("address") as string;
            const state = formData.get("state") as string;
            const country = formData.get("country") as string;
            const id = profile.id;

          const data = {
              id,
              name,
              contactNo,
              emailId,
              city,
              address,
              state,
              country
          }
         
        try {
          const response = await ClientService.updateClient(data);
          if (response.status === 200) {
            getClientDetailsByEmail();
          }
          response.status === 200
            ? toast.success(response.data)
            : toast.error(response.data);
        } catch (error) {
          toast.error("Problem to update profile details");
        }
      };

    return(
        <Grid container spacing={2}>
            <Grid item md={12} className="panel-head-grid">
                <h3>Your Profile Details</h3>
                <Divider /><br/>
            </Grid>
            <Grid
                container
                spacing={2}
                component="form"
                onSubmit={updateClient}
            >
                <Grid item md={1}></Grid>
                    <Grid item md={10}>
                        <Paper style={{ padding: '20px',maxHeight: 580, }}>
                            <Grid container spacing={2}>
                            <Grid item md={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="name"
                                    label="Client Name"
                                    name="name"
                                    value={profile.name || ''}
                                    onChange={(event) => setProfile({ ...profile, name: event.target.value })}
                                />
                            </Grid>
                            <Grid item md={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="contact"
                                label="Contact No"
                                name="contact"
                                value={profile.contactNo || ''}
                                onChange={(event) => setProfile({ ...profile, contactNo: event.target.value })}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Id"
                                    name="email"
                                    value={profile.emailId || ''}
                                    disabled    
                                />
                            </Grid>
                            
                            <Grid item md={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="city"
                                    label="City"
                                    name="city"
                                    value={profile.city || ''}
                                    onChange={(event) => setProfile({ ...profile, city: event.target.value })}
                                />
                            </Grid>
                            <Grid item sm={12} md={12}>
                            <TextField
                                    margin="normal"
                                    fullWidth
                                    id="address"
                                    label="Address"
                                    name="address"
                                    multiline
                                    rows={4}
                                    value={profile.address || ''}
                                    onChange={(event) => setProfile({ ...profile, address: event.target.value })}
                                />
                            </Grid>
                            <Grid item md={6}>
                            <TextField
                                    margin="normal"
                                    fullWidth
                                    id="state"
                                    label="State"
                                    name="state"
                                    value={profile.state || ''}
                                    onChange={(event) => setProfile({ ...profile, state: event.target.value })}
                                />
                            </Grid>
                            <Grid item md={6}>
                            <TextField
                                    margin="normal"
                                    fullWidth
                                    id="country"
                                    label="Country"
                                    name="country"
                                    value={profile.country || ''}
                                    onChange={(event) => setProfile({ ...profile, country: event.target.value })}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <Button type="submit" variant="contained">Submit</Button>
                            </Grid>
                            </Grid>
                            </Paper>
                        </Grid>
                </Grid>
        </Grid>
    );
}