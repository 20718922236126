import { Bolt } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useRef } from 'react';
import Typewriter from '../../utils/TypeWriter';
import Answer from '../Answer';
import { environment } from '../../utils/environments/environment';

export default function ChatBox({
  showCB,
  // chatBotLogo,
  props,
  messages,
  msg,
  setIsTypingCompleted,
  isTypingCompleted,
  renderAnswerType,
  showHome }) {

  const contentRef = useRef(null);
  const scrollToBottom = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" });
    }
  }

  useEffect(() => {
    scrollToBottom();
  })

  return <>
    <Grid container >
      {showCB &&
        <div className={showHome ? 'chat-box' : 'chat-box-integration'}  >
          {<div className='chatBot-header' >
            <img src={environment.IMAGE_BASE_URL + props.themeSetting.logoPath} alt=''></img>
            <p id='chatBot-header-name'>{props.name}</p>
          </div>}
          <div className='content'>
            {messages && messages.msg && msg && msg.map((m, index) =>
              <div key={index}>
                {m.msg.map((item, itemIndex) =>
                (item.msg === null ? '' :
                  <div className='chat-que' key={itemIndex}>

                    <Typewriter text={item.msg} setIsTypingCompleted={setIsTypingCompleted} />

                  </div>)
                )}

                {(m.ans.msg === null || m.ans.msg === '') ? '' : <Answer data={m} index={index} />}

              </div>)}

            {isTypingCompleted && renderAnswerType()}

            <div className='bottom' ref={contentRef} ></div>
          </div>
          <div className="g-bot-branding-footer"><p><Bolt /> Powered by <a href='https://chat.gateway2it.com/' target='_blank' rel="noreferrer">Gateway2IT Pvt Ltd</a></p></div>
        </div>
      }
    </Grid>
  </>
}