import { CancelPresentation, FormatColorFill } from "@mui/icons-material";
import { Grid, IconButton, Paper } from "@mui/material";
import { useState } from "react";
import { SketchPicker } from 'react-color';

export default function ColorPicker({ handleThemeSettings, color, label, fieldName }) {

    const [isOpen, setIsOpen] = useState(false);

    const toggleColorPicker = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Paper elevation={3}>
            <p style={{ padding: '2px 10px' }}>{label}</p>
            <Grid container spacing={2} onClick={toggleColorPicker} style={{ cursor: "pointer" }}>
                <Grid item xs={10} sm={10} md={10}>
                    <div style={{ backgroundColor: color, height: '100%', marginLeft: '9px', marginTop: '-7px' }}></div>
                </Grid>
                <Grid item xs={2} sm={2} md={2} style={{ padding: '0px' }}>
                    {isOpen ?
                        <>
                            <IconButton color="secondary" aria-label="Close">
                                <CancelPresentation />
                            </IconButton>
                        </> :
                        <>
                            <IconButton color="primary" aria-label="Fill Color">
                                <FormatColorFill />
                            </IconButton>
                        </>
                    }
                </Grid>
            </Grid>
            {isOpen && (
                <div style={{ position: 'absolute', zIndex: "999" }}>
                    <SketchPicker
                        color={color}
                        onChange={(event) => { handleThemeSettings(event, fieldName) }}
                        disableAlpha={true}
                    />
                </div>
            )}
        </Paper>
    );
}