import axiosInstance from "../../../service/axiosInstance";
import { API_ROUTES } from "../../../utils/apiConfig";

export default class ConfigService {

    static async getAllIndustryDetails() {
        try {
          const response = await axiosInstance.get(API_ROUTES.INDUSTRY);
          return response;
        } catch (error: any) {
            return error.response;
        }
      }

      static async saveIndustry(data: any) {
        try {
          const response = await axiosInstance.post(`${API_ROUTES.INDUSTRY}`,data);
          return response;
        } catch (error: any) {
          return error.response;
        }
      }

      static async updateIndustry(data: any) {
        try {
          const response = await axiosInstance.post(`${API_ROUTES.INDUSTRY}/archive`,data);
          return response;
        } catch (error: any) {
          return error.response;
        }
      }

      static async getAllLanguageDetails() {
        try {
          const response = await axiosInstance.get(API_ROUTES.LANGUAGE);
          return response;
        } catch (error: any) {
            return error.response;
        }
      }

      static async saveLanguage(data: any) {
        try {
          const response = await axiosInstance.post(`${API_ROUTES.LANGUAGE}`,data);
          return response;
        } catch (error: any) {
          return error.response;
        }
      }

      static async updateLanguage(data: any) {
        try {
          const response = await axiosInstance.post(`${API_ROUTES.LANGUAGE}/archive`,data);
          return response;
        } catch (error: any) {
          return error.response;
        }
      }

      static async getAllSubscriptionDetails(status: any) {
        try {
          const response = await axiosInstance.get(API_ROUTES.SUBSCRIPTION + '?status=' + status);
          return response;
        } catch (error: any) {
            return error.response;
        }
      }

      static async saveSubscription(data: any) {
        try {
          const response = await axiosInstance.post(`${API_ROUTES.SUBSCRIPTION}`,data);
          return response;
        } catch (error: any) {
          return error.response;
        }
      }

      static async updateSubscription(data: any) {
        try {
          const response = await axiosInstance.post(`${API_ROUTES.SUBSCRIPTION}/update`,data);
          return response;
        } catch (error: any) {
          return error.response;
        }
      }

      static async archiveSubscription(data: any) {
        try {
          const response = await axiosInstance.post(`${API_ROUTES.SUBSCRIPTION}/archive`,data);
          return response;
        } catch (error: any) {
          return error.response;
        }
      }

      static async getAllSubscriptionByPaidPlan() {
        try {
          const response = await axiosInstance.get(`${API_ROUTES.SUBSCRIPTION}/paid-plan`);
          return response;
        } catch (error: any) {
            return error.response;
        }
      }
}