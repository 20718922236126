
import { DoneOutline } from '@mui/icons-material';
import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from "@mui/material/IconButton";
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Tooltip from '@mui/material/Tooltip';

export default function Swipper({ props, increaseSQWithDelay }) {

    const [selectedSwiper, setSelectedSwiper] = useState(null);

    return <div className='swiper-container'>
        <Swiper
            // pagination={{
            //   dynamicBullets: true,
            // }}
            // modules={[Pagination]}
            className="mySwiper"
            slidesPerView={2.5}
            spaceBetween={10}
        >
            {props.options.map((m, index) => (
                <SwiperSlide key={index}
                    onClick={() => {
                        setSelectedSwiper(m)
                    }}>

                    <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                            {m.img && <CardMedia
                                component="img"
                                height="100"
                                image={m.img}
                                alt="green iguana"
                            />}
                            <CardContent className={`card-content ${selectedSwiper && m === selectedSwiper ? 'selected' : ''}`}>
                                <Tooltip title={m.msg1} >
                                    <Typography gutterBottom component="div" className='swiper-card-title'>
                                        {m.msg1}
                                    </Typography>
                                </Tooltip>

                                <Typography variant="body2">
                                    {m.msg2}
                                </Typography>
                                <Typography variant="body2">
                                    {/* &#8377;  */}
                                    {m.msg3}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </SwiperSlide>
            )
            )}
        </Swiper>
        {<div className='cb-proceed-Swiper'>
            <IconButton className={selectedSwiper === null ? 'not-selected' : 'cb-ok'}
                onClick={() => increaseSQWithDelay(props, selectedSwiper.msg1, selectedSwiper)}
                sx={{ left: '23%' }}
                disabled={!selectedSwiper}
            >
                <DoneOutline />
            </IconButton>
        </div>}
    </div>

}