import { Grid } from "@mui/material";
import { BANNER, COUNTER, WORKS } from "../../assets/data/WebContent";
import Header from "./Header";
import HowItWorks from "./HowItWorks";
import PopularTemplate from "./PopularTemplate";
import Subscription from "./Subscription";
import "./Web_Style.css";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { aboutBot } from "../../assets/data/WebContent";
import ChatBot from "../chatBot/ChatBot";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

export default function Web() {
  const navigate = useNavigate();
  const subscriptionRef = useRef<HTMLDivElement>(null);

  const handlePricing = () => {
    if (subscriptionRef.current) {
      subscriptionRef.current.scrollIntoView({ behavior: 'smooth'});
    }
  };

  const handleTermConditionClick = () => {
    navigate("/term&condition");
  };
  
   const registration = () => {
    navigate("/registration");
  };

  return (
    <div className="web-app">
      <div className="only-chat-bot">
        <ChatBot showOnlyBot={true} />
      </div>
      <div className="banner">
        <Grid container spacing={2}>
          <Grid item md={12} style={{ marginBottom: "5%" }}>
            <Header handlePricing={handlePricing}/>
          </Grid>
          <Grid item md={6}>
            <div className="bot-banner-div">
              <h1 className="bot-head">{BANNER.heading}</h1>
              <h3 className="bot-sub-head">{BANNER.title}</h3>
              <p className="bot-content">{BANNER.subtitle}</p>
              <p className="bot-content">{BANNER.tag}</p>
              <Grid container>
                <Grid item md={2}></Grid>
                <Grid item md={4}>
                  <a className="create-btn" onClick={registration}>{BANNER.btn1}</a>
                </Grid>
                <Grid item md={4}>
                  <a className="create-btn contact-btn" href="mailto:chat@gateway2it.com">{BANNER.btn2}</a>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item md={6} sx={{ pr: 10, mt: 5 }}>
            <div className="pri-cer">
              <div className="sec-cer">
                <img
                  src={require("../../assets/images/web/1.png")}
                  style={{ width: "75%" }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="bubble bubble--1"></div>
        <div className="bubble bubble--2"></div>
        <div className="bubble bubble--3"></div>
        <div className="bubble bubble--4"></div>
        <div className="bubble bubble--5"></div>
        <div className="bubble bubble--6"></div>
        <div className="bubble bubble--7"></div>
        <div className="bubble bubble--8"></div>
        <div className="bubble bubble--9"></div>
        <div className="bubble bubble--10"></div>
        <div className="bubble bubble--11"></div>
        <div className="bubble bubble--12"></div>
      </div>
      <Grid container spacing={2}>
        <Grid item md={1}></Grid>
        <Grid item md={10}>
          <h3 className="wcu-h3">Why Choose Us?</h3>
          <h3 className="wcu-head-tag">
            How GBOT accelerate your growth of Business?
          </h3>
          <Grid container spacing={2}>
            {WORKS.map((item, index) => (
              <Grid item sm={6} md={4} key={index}>
                <a className="card1" href="#">
                  <div className="card-head">
                    <div className="card1-icon-div">{item.icon}</div>
                    <p className="card-head-p">{item.heading}</p>
                  </div>
                  <p className="small">{item.text}</p>
                  <div className="go-corner">
                    <div className="go-arrow">→</div>
                  </div>
                </a>
              </Grid>
            ))}
            ;
          </Grid>

          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <img
                src={require("../../assets/images/web/2.jpg")}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <h3 className="wcu-h3">About GBOT</h3>
              <div className="about-container">
                <div className="ag-head-tag">
                  GBOT specializes in providing cutting-edge chatbot solutions
                  to businesses looking to enhance customer engagement and
                  streamline operations. Our user-friendly chatbots are designed
                  to deliver efficient and personalized support, helping
                  businesses save time and resources while improving customer
                  satisfaction.
                </div>
                {aboutBot.map((item, index) => (
                  <div className="about-cc" key={index}>
                    <CheckBoxOutlineBlankIcon className="web-icon" />
                    <span>
                      <b> {item.name} </b> {item.contant}
                    </span>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="counter-bg">
        <Grid container spacing={2}>
          {COUNTER.map((item, index) => (
            <Grid item xs={3} sm={3} md={3} key={index}>
              <div className="counter-head">
                <h3>
                  {item.value} <span className="sign-icon">+</span>
                </h3>
                <p>{item.title}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={1}></Grid>
        <Grid item md={10}>
          <h3 className="wcu-h3" ref={subscriptionRef}>
            Automating your customer queries is just a step away
          </h3>
          <p className="pricing-head-tag">
            Try GBOT free for 30 days, no credit card required.
          </p>
          <Subscription action={registration}/>

          <h3 className="wcu-h3">How it Works</h3>
          <HowItWorks />

          <h3 className="wcu-h3">Popular Templates</h3>
          <p className="pricing-head-tag">Showing 3 Most Popular Templates</p>
          <PopularTemplate action={registration}/>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <div className="copy-right-area">
            <p style={{ cursor: "pointer" }} onClick={handleTermConditionClick}>
              Terms & Condition
            </p>
            <p> © 2024 GBot Powered by Gateway2IT</p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
