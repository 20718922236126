import { Grid } from "@mui/material";
import { HowItWorksData } from "../../assets/data/WebContent";
import "./Web_Style.css";

export default function HowItWorks() {
    return <Grid container spacing={2} className="htw-container">
        {HowItWorksData.map((item, index) =>
            <Grid item sm={6} md={4} key={index}>
                <div className="htw-div">
                    <div className="htw-img-div">
                        <img src={require(`../../assets/images/web/howItWork/${item.img}`)} alt="" />
                    </div>
                    <div className="htw-content-div">
                        <h3>{item.heading}</h3>
                        <p>{item.discription}</p>
                    </div>
                    <div className="htw-btn-div">
                        <a className="create-btn contact-btn">Try it now</a>
                    </div>
                </div>
            </Grid>)

        }
    </Grid>
}