import React from "react";
import Typography from '@mui/material/Typography';
import {MoreVert, Delete, Edit, Visibility} from '@mui/icons-material';
import Popover from '@mui/material/Popover';

const MoreAction = (props: any) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover-' + props.id : undefined;
    return(
        <>
        <span className='que-more' onClick={handleClick}><MoreVert/></span>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
          >
            <Typography sx={{ p: 2 }} className="more-action">
                <Visibility />
                <Edit />
                <Delete />
            </Typography>
          </Popover>
        </>
    );
}

export default MoreAction;