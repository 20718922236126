
import { DoneOutline } from '@mui/icons-material';
import { TextField } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from 'react';

export default function Mobile({ props, increaseSQWithDelay }) {

    const [error, setError] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');

    const handleMobileNumberChange = (event) => {
        const inputValue = event.target.value;
        const formattedValue = inputValue.replace(/\D/g, '').slice(0, 10);
        setMobileNumber(formattedValue);
    };

    const handleClick = (event) => {
        const inputValue = event.currentTarget.closest('.MuiFormControl-root').querySelector('input').value;

        if (props.isSkipped && mobileNumber.length === 0) {
            increaseSQWithDelay(props, null, null);
        }

        if (mobileNumber.length === 10) {
            increaseSQWithDelay(props, inputValue, null);
        } else {
            setError(true);
        }
    }

    return <div className='outer-mobile-div' key={props.sq}><TextField
        label="Mobile Number"
        variant="outlined"
        value={mobileNumber}
        helperText={error ? <span style={{ color: 'red', fontWeight: '900' }}>Required*</span> : ""}
        onChange={handleMobileNumberChange}
        className="custom-textfield"
        InputProps={{
            pattern: '[0-9]*',
            inputMode: 'numeric',
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        onClick={handleClick}
                        className='custom-textfield-icon-btn'>
                        <DoneOutline />
                    </IconButton>
                </InputAdornment>
            ),
        }}
    /></div>
}