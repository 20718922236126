import React, { useEffect } from "react";

export default function Start({ props, increaseSQWithDelay }) {
    useEffect(() => {
        const delayedExecution = () => {
            increaseSQWithDelay(props, null, null);
        };
        const timeoutId = setTimeout(delayedExecution, 1000);
        return () => clearTimeout(timeoutId);
    }, [props, increaseSQWithDelay]); 

    return <></>;
}
