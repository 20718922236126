import { Box, Button, Grid, Paper } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoginService from "../../../service/LoginService";

export default function OtpVerification() {
  
  const [otp, setOtp] = useState("");
  const { state } = useLocation();
  const emailId = state && state.data ? state.data : "";

  const handleChange = (newValue: any) => {
    setOtp(newValue);
  };

  const navigate = useNavigate();
  const login = () => {
    navigate("/login");
  };

  const verifyOtp = async (event: React.FormEvent<HTMLFormElement> | any) => {
    event.preventDefault();
    if (!otp.trim()) {
      toast.error("Please Enter OTP");
      return;
    }

    const data = {
      otp,
      emailId,
    };

    try {
      const response = await LoginService.verifyOTP(data);
      if (response.status === 200) {
        localStorage.removeItem("emailforotp");
        login();
      }
      response.status === 200
        ? toast(
            "Your Login Password send in your registered Email Id: " + emailId
          )
        : toast.error("Please Enter Valid OTP");
    } catch (error) {
      toast.error("Please Enter Valid OTP");
    }
  };

  const validateChar = (text: any) => {
    return !isNaN(Number(text));
  };

  const [timer, setTimer] = useState(60);

  useEffect(() => {
    let interval = setInterval(() => timer > 0 && setTimer(timer - 1), 1000);
    return () => clearInterval(interval);
  }, [timer]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={1} lg={2}></Grid>
        <Grid item md={10} lg={8} className="login-pan">
          <Paper className="login-pan-paper">
            <Grid container>
              <Grid item sm={6} md={6} className="login-side-pan">
                <img
                  src={require("../../../assets/images/auth/otp.png")}
                  style={{ width: "75%" }}
                  alt=""
                />
              </Grid>
              <Grid item sm={6} md={6} className="login-form-pan">
                <Grid container>
                  <Grid item md={1}></Grid>
                  <Grid item md={10}>
                    <h3 className="login-hel">Hello, Welcome Back!</h3>
                    <br />
                    <Box
                      className="form"
                      component="form"
                      onSubmit={verifyOtp}
                      noValidate
                    >
                      <MuiOtpInput
                        className="mat-otp-input"
                        length={6}
                        TextFieldsProps={{ placeholder: "-" }}
                        value={otp}
                        validateChar={validateChar}
                        onChange={handleChange}
                      />
                      <div className="email-timer">
                        <div>
                          OTP send to Email: <b>{emailId}</b>
                        </div>
                        <div>
                          Resend OTP: <b>{timer}</b>
                        </div>
                      </div>
                      <Grid container sx={{ marginTop: 2 }}>
                        <Grid item xs={12} md={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            className="login-btn"
                            disabled={otp.length !== 6}
                          >
                            Verify OTP
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
