import 'swiper/css';
import 'swiper/css/pagination';
import ChatBot from './chatBot/ChatBot';

function Question() {

  return (<>
    <div className="container">
      <ChatBot showOnlyBot={false}/>
      <div className='bot-footer'> © 2024 GBot Powered by &nbsp; <a href='https://gateway2it.com' style={{textDecoration: "none", color: "white"}}> Gateway2IT</a></div>
    </div>

  </>);
}

export default Question;