import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BotService from "../../../service/BotService";
import ConfirmationDialog from "../../../utils/ConfirmationDialog";
import { ROLE } from "../../../utils/apiConfig";
import DesktopView from "./DesktopView";
import MobileView from "./MobileView";
const Dashbord = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const role = JSON.parse(localStorage.getItem("currentUser") || "{}").role;
  const clientId = JSON.parse(localStorage.getItem("currentUser") || "{}").email;
  const [openDialog, setOpenDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState('');

  const handleSwitch = async (e) => {
    setOpenDialog(true);
    setCurrentItem(e);
  };

  const handleConfirm = async () => {
    try {
      const response = await BotService.activateBot(currentItem.id);
      if (response) {
        if (response.status === 200) {
          toast(response.data);
          fetchData();
        } else {
          toast(response.data);
        }
      }
      setOpenDialog(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleClose = () => {
    setOpenDialog(false);
    setCurrentItem('');
  }

  const fetchData = async () => {
    try {
      let response;
      if (role === ROLE.CLIENT) {
        response = await BotService.getAllBotByClientEmailId(
          clientId
        );

      } else if (role === ROLE.SUPER_ADMIN) {
        response = await BotService.getAllBot();
      }

      if (response) {
        if (response.status === 200) {
          const formattedData = response.data.map((bot) => ({
            ...bot,
            active: Boolean(bot.active),
          }));
          setRows(formattedData);

        } else {
          toast(response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  const handleReportClick = (item) => {
    navigate("/report/user-query", { state: { data: item } });
  };
// console.log(rows);
  return (
    <>
      <DesktopView
        role={role}
        rows={rows}
        handleSwitch={handleSwitch}
        handleReportClick={handleReportClick}
      />

      <MobileView
        role={role}
        rows={rows}
        handleSwitch={handleSwitch}
        handleReportClick={handleReportClick}
      />

      <ConfirmationDialog
        open={openDialog}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title="Confirmation"
        message="Are you sure want to activate this bot?" />
    </>
  );
};

export default Dashbord;
