import React from "react";
import {ShortText,TextFields,Flaky,StarOutline,List,LocationOn,Password,CalendarMonth,DateRange,Schedule,CloudUpload,
    Language, AddShoppingCart as AddShoppingCartIcon} from '@mui/icons-material';

const queTypes = [
    {
        btnText: "Short Text",
        icon: <ShortText />,
        title: "Short Text",
        typeId: 1
    },
    {
        btnText: "Long Text",
        icon: <TextFields />,
        title: "Long Text",
        typeId: 2
    },
    {
        btnText: "Yes/No",
        icon: <Flaky />,
        title: "Yes/No",
        typeId: 3
    },
    {
        btnText: "Rating",
        icon: <StarOutline />,
        title: "Rating",
        typeId: 4
    },
    {
        btnText: "Multiple Choice",
        icon: <List />,
        title: "Multiple Choice",
        typeId: 5
    },
    {
        btnText: "Auto Complete",
        icon: <AddShoppingCartIcon />,
        title: "Auto Complete",
        typeId: 6
    },
    {
        btnText: "Spinner",
        icon: <ShortText />,
        title: "Spinner",
        typeId: 7
    },
    {
        btnText: "Number Input",
        icon: <ShortText />,
        title: "Number Input",
        typeId: 8
    },
    {
        btnText: "Location",
        icon: <LocationOn />,
        title: "Location",
        typeId: 9
    },
    {
        btnText: "Calendar",
        icon: <CalendarMonth />,
        title: "Calendar",
        typeId: 10
    },
    {
        btnText: "Time Picker",
        icon: <Schedule />,
        title: "Time Picker",
        typeId: 11
    },
    {
        btnText: "Date Range",
        icon: <DateRange />,
        title: "Date Range",
        typeId: 12
    },
    {
        btnText: "Number Range",
        icon: <ShortText />,
        title: "Number Range",
        typeId: 13
    },
    {
        btnText: "File Picker",
        icon: <CloudUpload />,
        title: "File Picker",
        typeId: 14
    },
    {
        btnText: "Button",
        icon: <ShortText />,
        title: "Button",
        typeId: 15
    },
    {
        btnText: "Password",
        icon: <Password />,
        title: "Password",
        typeId: 16
    },
    {
        btnText: "Language",
        icon: <Language />,
        title: "Language",
        typeId: 17
    }
];

export default queTypes;