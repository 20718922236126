import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoginService from "../../../service/LoginService";

const CLIENT_ID = '755181701161-49vn8c7us1drpmaclh2likktafrknkn4.apps.googleusercontent.com';

export default function SignUp() {
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();
  const login = () => {
    navigate("/login");
  };

  const otpVerification = (emailId: string) => {
    navigate("/otp-verification", { state: { data: emailId } });
  };

  const onSuccess = (response: any) => {
    console.log('[Login Success] currentUser:', response.profileObj);
  };

  const onFailure = (response: any) => {
    console.log('[Login Failed] res:', response);
  };

  const onLogoutSuccess = () => {
    console.log('Logout made successfully');
  };

  const saveClient = async (event: React.FormEvent<HTMLFormElement> | any) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const name = formData.get("name") as string;
    const emailId = formData.get("email") as string;
    const password = formData.get("password") as string;
    const isVerified = false;
    const active = true;

    if (!name.trim()) {
      toast.error("Please Enter Name");
      return;
    }

    if (!emailId.trim()) {
      toast.error("Please Enter Email Id");
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(emailId)) {
      toast.error("Please Enter a Valid Email Id");
      return;
    }

    if (!password.trim()) {
      toast.error("Please Enter password");
      return;
    }

    if (password.length < 6) {
      toast.error("Password must contain at least 6 characters");
      return;
    }

    const data = {
      name,
      emailId,
      isVerified,
      active,
      password,
    };

    try {
      const response = await LoginService.registration(data);
      if (response.status === 200) {
        localStorage.setItem("emailforotp", emailId);
        otpVerification(emailId);
      }
      response.status === 200
        ? toast("Please find OTP in your registered Email Id: " + emailId)
        : toast.error(
            emailId +
              " this email id already registered with diffrent client please try with another"
          );
    } catch (error) {
      toast.error(
        emailId +
          " this email id already registered with diffrent client please try with another"
      );
    }
  };

  const handleClick = () => {
    setShowPass(!showPass);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={2}></Grid>
        <Grid item md={8} className="login-pan">
          <Paper className="login-pan-paper">
            <Grid container>
              <Grid item md={6} className="login-side-pan">
                <img
                  src={require("../../../assets/images/auth/reg.png")}
                  style={{ width: "75%" }}
                />
              </Grid>
              <Grid item md={6} className="login-form-pan">
                <span className="not-member">
                  Already Registered? <span onClick={login}>Login Now</span>
                </span>
                <br />
                <Grid container>
                  <Grid item md={1}></Grid>
                  <Grid item md={10}>
                    <h3 className="login-hel">Hello, Welcome To GBOT!</h3>
                    <Box
                      className="form"
                      component="form"
                      onSubmit={saveClient}
                      noValidate
                    >
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Id"
                        name="email"
                        autoComplete="email"
                      />
                      <TextField
                        type={showPass ? "text" : "password"}
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="Password"
                        name="password"
                        autoComplete="password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              className="eye-icon"
                              position="end"
                              onClick={handleClick}
                            >
                              {showPass ? (
                                <VisibilityOffIcon />
                              ) : (
                                <RemoveRedEyeIcon />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <br />
                      <Grid container>
                        <Grid item md={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            className="login-btn"
                          >
                            Sign Up
                          </Button>
                        </Grid>
                      </Grid>
                      {/* <Grid container>
                        <Grid item md={12}>
                          <p className="con-label">or continue with</p>
                          <img
                            src={require("../../../assets/images/auth/google-btn.png")}
                            className="g-btn"
                          />

                        <GoogleLogin
                                clientId={CLIENT_ID}
                                buttonText="Login with Google"
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                cookiePolicy={'single_host_origin'}
                              />
                              <GoogleLogout
                                clientId={CLIENT_ID}
                                buttonText="Logout"
                                onLogoutSuccess={onLogoutSuccess}
                              />
                        </Grid>
                      </Grid> */}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
