import Visibility from "@mui/icons-material/Visibility";
import { Grid } from "@mui/material";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../App";
import { EnquiryTable } from '../../../assets/data/tableConfig';
import BotService from '../../../service/BotService';
import ReactSkeleton from "../../../utils/ReactSkeleton";
import ReactTable from "../../../utils/ReactTable";
import { ROLE } from "../../../utils/apiConfig";
import Answer from "../../Answer";
import ClientService from "../services/ClientService";
import ChatBotDailog from "./ChatBotDialog";
import './Report.css';
import { toast } from "react-toastify";

export default function UserQueries() {
    const { state } = useLocation();
    const receivedData = state && state.data ? state.data : null;
    const role = JSON.parse(localStorage.getItem("currentUser") || "{}").role;
    const clientId = JSON.parse(localStorage.getItem("currentUser") || "{}").email;

    const { botConfig } = useContext(UserContext);
    const [tableData, setTableData] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [currentEnquiry, setCurrentEnquiry] = useState('');
    const [currentBot, setCurrentBot] = useState('');
    const [currentClient, setCurrentClient] = useState('');
    const [allClients, setAllClients] = useState('');
    const [allBots, setAllBots] = useState('');
    const [mobileView, setMobileView] = useState(false);

    useEffect(() => {
        if (allClients && receivedData) {
            setCurrentClient(receivedData.client.emailId);
            getBotByClient(receivedData.client.emailId);
        }
    }, [allClients, receivedData])

    useEffect(() => {
        if (receivedData && allBots) {
            setCurrentBot(receivedData.id);
            getBotEnquiry(receivedData.id)
        }
    }, [allBots, receivedData])

    const handleBotChange = (event) => {
        setCurrentBot(event.target.value);
        getBotEnquiry(event.target.value)
    };

    const handleCustomerChange = (event) => {
        setCurrentClient(event.target.value);
        getBotByClient(event.target.value);
    }

    const getBotByClient = async (id) => {
        const respose = await BotService.getAllBotByClientEmailId(id);
        if (respose.status === 200) {
            setAllBots(respose.data);
        } else {
            toast.warn(respose.data)
        }
    }

    const getAllCustomer = async () => {
        const respose = await ClientService.getAllClientDetails();
        if (respose.status === 200) {
            setAllClients(respose.data);
        } else {
            toast.warn(respose.data)
        }
    }

    useEffect(() => {
        if (role === ROLE.SUPER_ADMIN) {
            getAllCustomer();
        } else if (role === ROLE.CLIENT) {
            getBotByClient(clientId)
        }
    }, [clientId, role])

    const getBotEnquiry = async (botRequest) => {
        const respose = await BotService.getAllEnquiryByBotId(botRequest);
        if (respose.data) {
            const data = respose.data.map((item) => ({
                receivedOn: format(new Date(item.createdOn), 'yyyy/MM/dd kk:mm:ss'),
                enquiry: <div className="eye-button" onClick={() => { setShowDialog(true); setCurrentEnquiry(JSON.parse(item.data)) }}><Visibility /></div>
            }
            ))
            setTableData(data);
        }
    }

    const handleResize = () => {
        if (window.innerWidth < 770) {
            setMobileView(true);
        } else {
            setMobileView(false);
        }
    }

    window.addEventListener("resize", handleResize);

    useEffect(() => {
        if (window.innerWidth < 770) {
            handleResize();
        }
    })
    return <>
        <Box className="user-queries-container" sx={{ margin: 0, marginBottom: 2 }}>
            <Grid container sx={{ gap: 1 }}>
                {role === ROLE.SUPER_ADMIN &&
                    <Grid item xs={12} sm={5.9} md={4} lg={2}>
                        <FormControl className="user-queries-formcontrol">
                            <InputLabel id="demo-simple-select-label">Client</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={currentClient}
                                label="Client"
                                onChange={handleCustomerChange}
                            >
                                {allClients && allClients.map((item, index) =>
                                    <MenuItem value={item.emailId} key={index}>{item.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                }

                <Grid item xs={12} sm={5.9} md={4} lg={2}>
                    <FormControl className="user-queries-formcontrol">
                        <InputLabel id="demo-simple-select-label">Bot</InputLabel>
                        <Select
                            disabled={role === ROLE.SUPER_ADMIN ? !currentClient : false}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={currentBot}
                            label="Bot"
                            onChange={handleBotChange}
                        >
                            {allBots && allBots.map((item, index) =>
                                <MenuItem value={item.id} key={index}>{item.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
        <div className="content-container">
            <div className={mobileView ? "inner-content-mobile" : "inner-conrtent"} >
                <ReactTable columns={EnquiryTable} data={tableData} />
            </div>
            {!showDialog && !mobileView && <div className="inner-conrtent2"> <div className="skeleton-content"><ReactSkeleton /> </div></div>}
            {showDialog && mobileView && <ChatBotDailog currentEnquiry={currentEnquiry} showDialog={showDialog} setShowDialog={setShowDialog} botConfig={botConfig}></ChatBotDailog>}
            {showDialog && !mobileView && <div className="inner-conrtent2">
                <div className='chat-box2' >
                    <div className='content-box'>
                        {currentEnquiry && currentEnquiry.map((m, index) =>
                            <div key={index}>
                                {m.msg.map((item, itemIndex) =>
                                (item.msg === null ? '' :
                                    <div className='chat-que2' key={itemIndex}>
                                        <div> {item.msg}</div>
                                    </div>)
                                )}
                                {(m.ans.msg === null || m.ans.msg === '') ? '' : <Answer data={m} index={index} />}
                            </div>
                        )}
                    </div>
                </div>
            </div>}
        </div>
    </>
}