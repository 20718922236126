import React, { useState, useEffect } from 'react';

const Typewriter = ({ text, setIsTypingCompleted }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [typingCompleted, setTypingCompleted] = useState(false);

  useEffect(() =>{
    setIsTypingCompleted(typingCompleted);
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => {
        if (prevIndex < text.length) {
          return prevIndex + 1;
        } else {
          clearInterval(interval);   
          setTypingCompleted(true);
          return prevIndex;
        }
      });
    }, 50);

    return () => clearInterval(interval);
  }, [text]);

  return (
    <div style={{whiteSpace: "pre-line"}}>
      {text.slice(0, currentIndex)}
    </div>
  );
};

export default Typewriter;
