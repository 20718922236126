import { Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { botType } from "../../../../../assets/data/WebContent";
import { ROLE } from "../../../../../utils/apiConfig";
import ClientService from "../../../services/ClientService";
import ConfigService from "../../../services/ConfigService";

export default function BasicDetails({ setBotName, saveBotDetails }) {
    const [industryData, setIndustryData] = useState('');
    const [language, setLanguage] = useState('');
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState([]);
    const [allClients, setAllClients] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const role = JSON.parse(localStorage.getItem("currentUser") || "{}").role;
    const [activeDLButton, setActiveDLButton] = useState();
    const [selectedBotType, setSelectedBotType] = useState('');

    useEffect(() => {
        if (role === ROLE.SUPER_ADMIN) {
            getAllClient();
        } else if (role === ROLE.CLIENT) {
            getClientDetailsByEmail();
        }
    }, [role])

    useEffect(() => {
        getAllIndustryDetails();
        getAllLanguage();
    }, [])

    const getClientDetailsByEmail = async () => {
        const email = JSON.parse(localStorage.getItem("currentUser") || "{}").email;
        const response = await ClientService.getClientDetailsByUserName(email)
        if (response.status === 200) {
            setSelectedClient(response.data.id)
        }
    };

    const getAllClient = async () => {
        const response = await ClientService.getAllClientDetails();
        if (response.status === 200) {
            setAllClients(response.data);
        }
    }

    const getAllIndustryDetails = async () => {
        const response = await ConfigService.getAllIndustryDetails();
        if (response.status === 200) {
            setIndustryData(response.data);
        }
    }

    const getAllLanguage = async () => {
        const response = await ConfigService.getAllLanguageDetails();
        if (response.status === 200) {
            setLanguage(response.data);
        }
    }

    const handleClientChange = (event) => {
        setSelectedClient(event.target.value);
    }

    const selectIndustry = (event) => {
        setSelectedIndustry(event.target.value);
    };

    const changeLangugage = (event) => {
        setSelectedLanguage(event.target.value);
    };

    const handleButtonClick = (language) => {
        setActiveDLButton(language);
    }

    const selectBotType = (botType) => {
        setSelectedBotType(botType);
    }

    const addBotDetails = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const name = formData.get("name");
        const email = formData.get("email");

        if (!name.trim()) {
            toast.error("Please enter a name for the Bot.");
            return;
        }
        if (!email.trim()) {
            toast.error("Please enter a email for the Bot.");
            return;
        }

        if (selectedClient === '') {
            if (role === ROLE.SUPER_ADMIN) {
                toast.error("Please Select Client.");
                return;
            } else if (role === ROLE.CLIENT) {
                toast.error("Your Details Not Found, Please ReLogin");
                return;
            }
        }

        if (selectedIndustry === '') {
            toast.error("Please Select Industry.");
            return;
        }

        if (selectedLanguage.length === 0) {
            toast.error("Please Select Chat Language.");
            return;
        }

        if (selectedBotType === '') {
            toast.error("Please Select Bot Type.");
            return;
        }

        const data = {
            client: { id: selectedClient },
            name: name,
            email: email,
            languages: selectedLanguage,
            defaultLanguage: activeDLButton,
            industry: selectedIndustry,
            emailLangugae: activeDLButton,
            type: selectedBotType
        };
        
        saveBotDetails(data);
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleBotNameChange = (event) => {
        setBotName(event.target.value);
    }

    return (
        <Grid container spacing={2} sx={{ marginLeft: "1.5%", width: "98%" }}>
            <Grid
                container
                spacing={2}
                component="form"
                onSubmit={addBotDetails}
            >
                <Grid item md={1}></Grid>
                <Grid item md={10}>
                    <Grid container spacing={2} style={{ justifyContent: "center" }}>
                        {(role === ROLE.SUPER_ADMIN) &&
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                                    <InputLabel id="demo-select-small-label">Client</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={selectedClient}
                                        label="Client"
                                        onChange={handleClientChange}
                                    >
                                        {allClients && allClients.map((item, index) =>
                                            <MenuItem value={item.id} key={index}>{item.name}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>}
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="name"
                                label="Bot Name"
                                name="name"
                                size="small"
                                onChange={handleBotNameChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Id"
                                name="email"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                                <InputLabel id="demo-select-small-label">Industry</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={selectedIndustry}
                                    label="Industry"
                                    onChange={selectIndustry}
                                >
                                    {industryData && industryData.map((item, index) =>
                                        <MenuItem value={item.name} key={index}>{item.name}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                                <InputLabel id="demo-multiple-checkbox-label">Chat Languages</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={selectedLanguage}
                                    onChange={changeLangugage}
                                    input={<OutlinedInput label="Chat Languages" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {language && Object.entries(language).map(([key, value]) =>
                                        <MenuItem key={key} value={key}>
                                            <Checkbox checked={selectedLanguage.includes(key)} />
                                            <ListItemText primary={key} />
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        {(selectedLanguage.length > 0) && <Grid item md={6}>
                            <Paper elevation={2}>
                                <p className="default-lang-p">Select Default Language</p>
                                <div className="default-lang-parent-div">
                                    {selectedLanguage.map((item, index) =>
                                        <div className="lang-choice-btn" key={index}>
                                            <Button
                                                variant={activeDLButton === item ? 'contained' : 'outlined'}
                                                onClick={() => handleButtonClick(item)}
                                            >
                                                {item}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </Paper>
                        </Grid>}

                        <Grid item md={6}>
                            <Paper elevation={2}>
                                <p className="default-lang-p">Select Chat Box Type</p>
                                <div className="default-lang-parent-div">
                                    {botType.map((item, index) =>
                                        <div className="lang-choice-btn" key={index}>
                                            <Button
                                                variant={selectedBotType === item ? 'contained' : 'outlined'}
                                                onClick={() => selectBotType(item)}
                                            >
                                                {item}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item md={12}>
                            <Button type="submit" variant="contained">Save & Next</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}