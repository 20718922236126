import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// const pages = ['Pricing', 'Contact Us'];

function Header({ handlePricing }: { handlePricing: any }) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [logo, setLogo] = useState('g_logo2.png');
  const [logoText, setLogoText] = useState('white');

  const navigate = useNavigate();
  const login = () => {
    navigate("/login");
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    window.location.href = "mailto:chat@gateway2it.com";
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [isScrolled, setIsScrolled] = useState(false);
  const [textColor, setTextColor] = useState('inherit');

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 50) {
        setIsScrolled(true);
        setTextColor('black');
        setLogo('g_logo1.png');
        setLogoText('#15a8d5');
      } else {
        setIsScrolled(false);
        setTextColor('inherit');
        setLogo('g_logo2.png');
        setLogoText('inherit');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <AppBar
      position="fixed"
      style={{
        backgroundColor: isScrolled ? "#ffffff" : "transparent",
        boxShadow: "none",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            onClick={() => navigate("/")}
            variant="h6"
            noWrap
            component="a"
            style={{ color: textColor }}
            href="#"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              textDecoration: 'none',
              alignItems: 'center'
            }}
          >
            <img src={require(`../../assets/images/web/${logo}`)} alt='' style={{width: '5vh'}} />
            <span className='logo-name' style={{color: logoText}}>BOT</span>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx = {{color: textColor}}
            >
              {/* <MenuIcon /> */}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {/* {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu} sx={{color: textColor}}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))} */}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: textColor,
              textDecoration: 'none',
              fontSize: '25px'
            }}
          >
            <img src={require(`../../assets/images/web/${logo}`)} alt='' style={{width: '5vh'}} />
            <span className='logo-name' style={{color: logoText}}>BOT</span>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              onClick={handlePricing}
              sx={{ my: 2, color: textColor, display: "block" }}
            >
              Pricing
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: textColor, display: "block" }}
            >
              Contact Us
            </Button>
            {/* {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: textColor, display: 'block' }}
              >
                {page}
              </Button>
            ))} */}
          </Box>
          <Button sx={{ color: textColor }} onClick={login}>
            Login
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
