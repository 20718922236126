
import { DoneOutline } from '@mui/icons-material';
import { Autocomplete, TextField } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import { useState } from 'react';

export default function City({ props, increaseSQWithDelay }) {

    const [selectedCity, setSelectedCity] = useState(null);

    return <>
        <Autocomplete
            key={props.sq}
            freeSolo
            className='city-autocomplete'
            id="free-solo-2-demo"
            disableClearable
            options={props.options}
            getOptionLabel={(option) => option.msg}
            onChange={(event, value) => {
                setSelectedCity(value)
            }
            }
            renderInput={(params) => (
                <TextField className='city-textField'
                    {...params}
                    label={props.lable}
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                    }}
                />
            )}
        />
        {<div className='cb-proceed-city-autocomplete'>
            <IconButton className={selectedCity === null ? 'not-selected' : 'cb-ok'}
                onClick={() => increaseSQWithDelay(props, selectedCity.msg, selectedCity)} sx={{ left: '45%' }}
                disabled={!selectedCity}
            >
                <DoneOutline />
            </IconButton>
        </div>
        }
    </>
}