import { DoneOutline } from '@mui/icons-material';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from '@mui/material/TextField';
import { useState } from 'react';

export default function Number({ props, increaseSQWithDelay }) {

    const [error, setError] = useState(false);

    const handleClick = (event) => {
        const inputValue = event.currentTarget.closest('.MuiFormControl-root').querySelector('input').value;

        if (props.isSkipped && inputValue.trim() === '') {
            increaseSQWithDelay(props, inputValue, null);
        }

        if (inputValue.trim() !== '') {
            increaseSQWithDelay(props, inputValue, null);
        } else {
            setError(true);
        }
    }


    return <>
        <div className='outer-mobile-div' key={props.sq}>
            <TextField
                label={props.lable}
                variant="outlined"
                type='number'
                helperText={error ? <span style={{ color: 'red', fontWeight: '900' }}>Required*</span> : ""}
                className="custom-textfield"
                InputProps={{
                    pattern: '[0-9]*',
                    inputMode: 'numeric',
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClick}
                                className='custom-textfield-icon-btn'>
                                <DoneOutline />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            /></div>
    </>
}