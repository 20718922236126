import { format } from 'date-fns';

const FormattedDateCell = ( value: any ) => {
    if (!value || isNaN(new Date(value.value).getTime())) {
        return <div>Invalid Date</div>; // Display error message
      }
  const formattedDate = format(new Date(value.value), "dd/MM/yyyy HH:mm");
  
  return <div>{formattedDate}</div>;
};

export default FormattedDateCell;