import { DoneOutline } from '@mui/icons-material';
import IconButton from "@mui/material/IconButton";
import { useState } from 'react';
import { DateRange } from 'react-date-range';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export default function DateRangePiker({ props, increaseSQWithDelay }) {

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const [isDateSelected, setIsDateSelected] = useState(false);

  return <> <DateRange
    editableDateInputs={true}
    onChange={item => { setDateRange([item.selection]); setIsDateSelected(true) }}
    moveRangeOnFirstSelection={false}
    ranges={dateRange}
  />
    {<div className='cb-proceed-Swiper'>
      <IconButton className={isDateSelected ? 'cb-ok' : 'not-selected'}
        onClick={() => increaseSQWithDelay(props, dateRange[0], dateRange)} sx={{ left: '23%' }}
        disabled={!isDateSelected}>
        <DoneOutline />
      </IconButton>
    </div>
    }
  </>

}