import { Button, Grid} from "@mui/material";
import './Style.css';
import MCOption from "./MCOPtion";
import { useState } from "react";

const MultipleChoice = () => {
    const removeComponent = (keyToRemove:any) => {
        setComponents(prevComponents => prevComponents.filter(component => component.props.id !== keyToRemove));
      };
    const [components, setComponents] = useState([<MCOption id={1} key={1} removeOption={removeComponent}/>]);
    const [nextKey, setNextKey] = useState(2);

    const addMoreOption = () => {
        setComponents(prevComponents => [
        ...prevComponents,
        <MCOption id={nextKey} key={nextKey} removeOption={removeComponent}/>
        ]);
        setNextKey(prevKey => prevKey + 1);
    };

  
  
    return(
        <>
        {components.map(component => (
          <div key={component.key}>
            {component}
          </div>
        ))}
        <Grid container spacing={2}>
          <Grid item md={12} sx={{ padding: '8px', display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" onClick={addMoreOption}>Add More Option</Button>
          </Grid>
        </Grid>   
        </>
    );
}

export default MultipleChoice;