import { API_ROUTES } from "../utils/apiConfig";
import { environment } from "../utils/environments/environment";
import axiosInstance from "./axiosInstance";

export default class BotService {

  static async getBotDetails(endPoint: any) {
    const data = {
      key: endPoint,
      validator: environment.VALIDATOR
    }
    try {
      const response = await axiosInstance.post( API_ROUTES.GET_BOT, data);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  static async placeEnquiry(data: any) {
    try {
      const response = await axiosInstance.post(API_ROUTES.PLACE_ENQUIRY, data);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  static async getAllEnquiryByBotId(id: any) {
    const data = {
      key: id,
      validator: environment.VALIDATOR
    }
    try {
      const response = await axiosInstance.post(API_ROUTES.GET_ENQUIRY, data);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  static async updateVisitor(id: any) {
    const data = {
      key: id,
      validator: environment.VALIDATOR
    }
    try {
      const response = await axiosInstance.post(API_ROUTES.UPDATE_VISITOR, data);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  static async createNewBot(data: any) {
    
    try {
      const response = await axiosInstance.post(`${API_ROUTES.BOT}add`, data);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  static async getAllBot() {
    try {
      const response = await axiosInstance.get(API_ROUTES.BOT);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  static async getAllBotByClientEmailId(id: any) {
    const data = {
      key: id,
      validator: environment.VALIDATOR
    }
    try {
      const response = await axiosInstance.post(API_ROUTES.BOT_BY_CLIENT, data);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  static async activateBot(id: any) {
    const data = {
      key: id,
      validator: environment.VALIDATOR
    }
    try {
      const response = await axiosInstance.post(API_ROUTES.ACTIVATE_BOT, data);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  static async getSubscriptionDetails() {
    try {
      const response = await axiosInstance.get(API_ROUTES.BOT);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  static async addThemeSettingToBot(data: any) {
    try {     
      const response = await axiosInstance.post(`${API_ROUTES.BOT}update-theme`, data, 
      {
        headers: {
        'Content-Type': 'application/octet-stream' 
        }
      }
      );
      return response;
    } catch (error: any) {
      return error.response;
    }
  }
}
