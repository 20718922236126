import { createContext, useEffect, useReducer, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import BotService from '../src/service/BotService';
import './App.css';
import Navigation from './Routing';
import { LINK } from './utils/apiConfig';
import { initialState, reducer } from './utils/userReducer';

export const UserContext = createContext();

function App() {
  const [botConfig, setBotConfig] = useState('');
  const [themeSetting, setThemeSetting] = useState('');

  useEffect(() => {
    const urlSegments = window.location.href.split('/');
    const lastSegment = urlSegments[urlSegments.length - 1];

    if (urlSegments.includes(LINK.BOT) || urlSegments.includes("chat")) {
      getBotConfig(lastSegment);
    } else {
      getBotConfig("");
    }
  }, []);

  const getBotConfig = async (lastSegment) => {
    //const endPoint = encodeURIComponent(lastSegment)
    const response = await BotService.getBotDetails(lastSegment);
    if (response) {
      setBotConfig(response.data);
    }

    if (response.data !== '') {
      setThemeSetting(response.data.themeSetting)
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <div className="App" style={{
      "--primary-color": themeSetting.themeColor,
      "--hover-text-color": themeSetting.hoverTextColor,
      "--text-color": themeSetting.textColor,
      "--background-color": themeSetting.backgroundColor,
      "--border-color": themeSetting.borderColor,
      '--hover-background-color': themeSetting.hoverBackgroundColor,
      "--correct-button-color": themeSetting.correctButtonColor,
      "--reset-button-color": themeSetting.resetButtonColor,
      "--scroll-bar-color": themeSetting.scrollBarColor,
      "--scroll-bar-hover-color": themeSetting.scrollBarHoverColor,
      "--bot-background-color": themeSetting.botBackgroundColor,
    }} >
      <UserContext.Provider value={{ state, dispatch, botConfig }}>
        <BrowserRouter>
          <Navigation />
        </BrowserRouter>
      </UserContext.Provider>
    </div>
  );
}

export default App;
