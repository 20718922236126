import EndPointLink from "../../utils/EndPointLink";
import FormattedDateCell from "../../utils/FormattedDateCell";

export const IndustryTable = [
  { field: "name", headerName: "Name", minWidth: 170, sortable: true },
  {
    field: "createdOn",
    headerName: "Created On",
    minWidth: 170,
    sortable: true,
    renderCell: (params: any) => <FormattedDateCell value={params.value} />,
  },
  {
    field: "action",
    headerName: "Action",
    minWidth: 150,
    condition: true,
    sortable: false,
  },
];

export const LanguageTable = [
  { field: "language", headerName: "Language", minWidth: 170, sortable: true },
  {
    field: "createdOn",
    headerName: "Created On",
    minWidth: 170,
    sortable: true,
    renderCell: (params: any) => <FormattedDateCell value={params.value} />,
  },
  {
    field: "action",
    headerName: "Action",
    minWidth: 150,
    condition: true,
    sortable: false,
  },
];

export const ClientTable = [
  { field: "name", headerName: "Name", minWidth: 170, sortable: true },
  {
    field: "contactNo",
    headerName: "Contact No",
    minWidth: 170,
    sortable: true,
  },
  { field: "emailId", headerName: "Email Id", minWidth: 170, sortable: true },
  { field: "city", headerName: "City", minWidth: 170, sortable: true },
  { field: "state", headerName: "State", minWidth: 170, sortable: true },
  {
    field: "createdOn",
    headerName: "Created On",
    minWidth: 170,
    sortable: true,
    renderCell: (params: any) => <FormattedDateCell value={params.value} />,
  },
  {
    field: "action",
    headerName: "Action",
    minWidth: 150,
    condition: true,
    sortable: false,
  },
];

export const SubscriptionTable = [
  { field: "name", headerName: "Name", minWidth: 170, sortable: true },
  { field: "price", headerName: "Price", minWidth: 170, sortable: true },
  { field: "noOfChats", headerName: "Chats", minWidth: 170, sortable: true },
  {
    field: "noOfVisitor",
    headerName: "Visitor",
    minWidth: 170,
    sortable: true,
    visible: false,
  },
  { field: "duration", headerName: "Validity", minWidth: 170, sortable: true },
  {
    field: "gBotBranding",
    headerName: "Remove Branding",
    minWidth: 170,
    sortable: true,
  },
  {
    field: "createdOn",
    headerName: "Created On",
    minWidth: 170,
    sortable: true,
    renderCell: (params: any) => <FormattedDateCell value={params.value} />,
  },
  {
    field: "action",
    headerName: "Action",
    minWidth: 150,
    condition: true,
    sortable: false,
  },
];

export const BotTable = [
  { field: "name", headerName: "Name", minWidth: 170, sortable: true },
  {
    field: "defaultLanguage",
    headerName: "Bot Default Language",
    minWidth: 170,
    sortable: true,
  },
  {
    field: "usedChat",
    headerName: "Used Chats",
    minWidth: 170,
    sortable: true,
  },
  {
    field: "noOfVisitor",
    headerName: "Visitor",
    minWidth: 170,
    sortable: true,
    visible: false,
  },
  {
    field: "endPoint",
    headerName: "Bot URL",
    minWidth: 170,
    sortable: true,
    // renderCell: (params: any) => <EndPointLink value={params.value} />,
  },
  { field: "industry", headerName: "Industry", minWidth: 170, sortable: true },
  {
    field: "createdOn",
    headerName: "Created On",
    minWidth: 170,
    sortable: true,
    renderCell: (params: any) => <FormattedDateCell value={params.value} />,
  },
  {
    field: "action",
    headerName: "Action",
    minWidth: 150,
    condition: true,
    sortable: false,
  },
];

export const EnquiryTable = [
  {
    accessorKey: "receivedOn",
    header: "Received On",
    size: 150,
  },
  {
    accessorKey: "enquiry",
    header: "Enquiry",
    size: 150,
  },
];

export const ClientTable2 = [
  { accessorKey: "name", header: "Name", size: 170 },
  { accessorKey: "contactNo", header: "Contact No", size: 170 },
  { accessorKey: "emailId", header: "Email Id", size: 170 },
  { accessorKey: "city", header: "City", size: 170 },
  { accessorKey: "state", header: "State", size: 170 },
  { accessorKey: "createdOn", header: "Created On", size: 170 },
  { accessorKey: "action", header: "Action", size: 150 },
];

export const billingTable = [
  {
    accessorKey: "subscriptionName",
    header: "Subscription Name",
    size: 150,
  },
  {
    accessorKey: "createdOn",
    header: "Purchase Date",
    size: 150,
  },
  {
    accessorKey: "noOfChats",
    header: "Total Chats",
    size: 150,
  },
  {
    accessorKey: "noOfUsedChats",
    header: "Used Chats",
    size: 150,
  },
  {
    accessorKey: "noOfVisitor",
    header: "Total Visitors",
    size: 150,
  },
  {
    accessorKey: "noOfUsedVisitor",
    header: "Used Visitors",
    size: 150,
  },

  {
    accessorKey: "status",
    header: "Status",
    size: 150,
  },
];
