import { Route, Routes } from "react-router-dom";
import { AdminRouter, BotRouter, ChatRouter, MainRouter } from "./Routers";
import AdminLayout from "./layout/AdminLayout";
import BotLayout from "./layout/BotLayout";
import Layout from "./layout/Layout";

const Navigation = () => {
  return (
    <>
      <Routes>
        {MainRouter.map((item, index) => (
          <Route
            exact ={item.exact}
            path={item.path}
            key={index}
            element={
              <Layout>
                <item.component />
              </Layout>
            }
          />
        ))}

        {AdminRouter.map((item, index) => (
          <Route
            exact={item.exact}
            path={item.path}
            key={index}
            element={
              <AdminLayout>
                <item.component />
              </AdminLayout>
            }
          />
        ))}

        {BotRouter.map((item, index) => (
          <Route
            exact={item.exact}
            path={item.path}
            key={index}
            element={
              <BotLayout>
                <item.component />
              </BotLayout>
            }
          />
        ))}

        {ChatRouter.map((item, index) => (
          <Route
            exact={item.exact}
            path={item.path}
            key={index}
            element={
              <Layout>
                <item.component />
              </Layout>
            }
          />
        ))}
      </Routes>
    </>
  );
};

export default Navigation;
