import { Grid, Paper } from "@mui/material";
import { useEffect } from "react";
import Header from "./Header";

export default function TermAndCondition() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <>
        <Header />
        <img src={require("../../assets/images/Terms-Conditions.jpg")} style={{ width: "100%" }} alt=""></img>
        <Grid container sx={{ justifyContent: "center", paddingBottom: "2vh", marginTop: "-4vh" }}>
            <Grid item xs={10} sm={10} md={10} lg={10}>
                <Paper elevation={3} sx={{ padding: "1vh 0" }}>
                    <div className="main-contaier">
                        <h1>Acceptance of Terms and Conditions</h1>

                        <div>User (You/Your) is requested to read these terms and conditions (T&C) carefully before accessing the online subscription services available at www.chat.gateway2IT.com (Website) provided by Gateway2IT Private Ltd. These T&C constitute a valid and binding agreement between Gateway2IT and You. By accessing, browsing, or otherwise using this Website for the Services, You warrant that You have fully read and understood these T&C and agree to be legally bound by these terms and acknowledge unconditional acceptance without limitation or qualification of these T&C. The following T&C and any amendments or modifications made thereto along with the general terms of use of using the Website, govern the use of the Website for the Services.</div>

                        <h3>Definitions </h3>

                        <div>Customer means any individual / firm / proprietorship / company etc. competent to enter into contract as per the Indian Contract Act, 1872 and interested in purchasing a Subscription online with Gateway2IT Private Ltd. through the Website.
                            Subscription means the online subscription services provided by Gateway2IT Private Ltd. through the Website.
                            Membership Policy</div>

                        <div>Transaction on the Platform is available only to persons who can form legally binding contracts under the Indian Contract Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Platform. If you are a minor i.e., under the age of 18 years, you may use the Platform or access content on the Platform only under the supervision and prior consent/permission of a parent or legal guardian.</div>

                        <div>As a minor, if you wish to transact on the Platform, such transaction on the Platform may be made by your legal guardian or parents. Gateway2IT reserves the right to terminate your membership and/or refuse to provide you with access to the Platform if it is brought to Gateway2IT’s notice or if it is discovered that You are under the age of 18 years and transacting on the Platform.</div>

                        <h3>Cancellation Policy</h3>

                        <div>'Cancellation' facility is not provided by our Company. Customer can not Cancellation the subscription once he/she puchased.</div>

                        <h3>Refund Policy</h3>

                        <div>Refund is not applicable.</div>

                        <h3>Returns Policy</h3>

                        <div>'Return' facility is not provided by our Company. Customer can not return the subscription once he/she puchased.</div>

                        <h3>Price of the Subscription</h3>

                        <div style={{ display: "flex", flexDirection: "column", gap: "1vh" }}>
                            <div>The price of the Subscription shall be as applicable on the date of invoice.
                                The prices are subject to change, and we are not responsible/legally bound to inform you in advance. Information related to pricing is only indicative and may not reflect the final price payable.
                                purchase of Subscription</div>

                            <div>The online purchase facility provided on this Website is for the convenience of the Customer.
                                A Customer can book more than 1 (one) Subscription, and the purchase process would be 1 (one) quantity per purchase.
                                The Customer shall be solely responsible for providing accurate information while making online purchases and the information shall be in English language only.
                                In the event that the Customer is purchase on behalf of another person, the necessary details of such other person are also required to be mentioned, and the requisite details of such other person are required to be produced as per requirement. Customer further acknowledges that the Customer shall be liable to indemnify Gateway2IT against all claims, losses, damages, or costs that may be incurred or suffered by Gateway2IT, its directors, employees, and representatives arising out of or in connection with such purchase.
                                Acceptance of Payment towards purchase</div>

                            <div> For purchase online, the Customer is required to pay the specified purchase amount as displayed on the Website at the time of purchase the product selected by the Customer (purchase Amount). Payment of the purchase Amount can be made online by Credit Card/Debit Card or Net Banking or Wallet or UPI payable in favour of Gateway2IT.
                                Acceptance of payment of purchase Amount on behalf of Gateway2IT is undertaken by a third-party payment gateway (Agency).
                                The online purchase is available at www.chat.gateway2IT.com whereas the payment gateway is powered by an intermediary Agency providing payment gateway and merchant services.
                                Gateway2IT is only a facilitator in the transaction and has suitably made the Website available for online purchase of Subscription and transfer of the amount from Customer account to the Gateway2IT account. Hence, Gateway2IT will not be responsible for any charges imposed and action taken by the Agency arising out of payment for online purchase. In the event the Customer suffers any kind of loss or damage arising out of the payment transaction made by the Customer on the Website, Gateway2IT shall not be responsible and the Customer will have recourse to claim damages only from the Agency.
                                The payment made by the Customer for online purchase of Subscription through the Agency is subject to the terms and conditions as provided by the Agency. Gateway2IT shall not be responsible for payment issues made in respect of online purchase through the Agency.
                                The transactions, purchase Amount and all other commercial terms such as balance payment, delivery of Subscription etc., shall be as per the bipartite contractual obligations agreed between the Customer and Gateway2IT. The payment gateway facility is arranged only to facilitate the completion of the online purchase transaction in a smooth and swift manner. Use of the payment gateway facility shall not render Gateway2IT liable or responsible for the non-delivery, non-receipt, non-payment, damage, breach of representations and warranties, non-provision of after-sales or warranty services, or fraud as regards the products and/or services listed on the Website.
                                The purchase Amount remitted by the Customer will be realized and transferred to the respective Gateway2IT account, which the Customer selected at the time of making the online purchase.
                                The Customer authorizes the Agency engaged by Gateway2IT to collect, process, facilitate, and remit payments and/or the transaction electronically in respect of transactions through the payment gateway facility. The Customer understands, accepts, and agrees that the online payment facility provided by Gateway2IT is neither a banking nor financial service but is merely a facilitator providing automated online electronic payment, for the transactions on the Website using the existing authorized banking infrastructure and payment gateway networks. By providing the payment gateway facility, Gateway2IT is neither acting as a trustee nor acting in a fiduciary capacity with respect to the transactions undertaken by the Customer on the Website.
                                Gateway2IT reserves the right to restrict the number of online purchases which a Customer can make through his/her Credit Card / Debit Card / Bank Account/ or any other financial instrument and accordingly reserves its right to reject an online purchase made by a Customer, without assigning any reasons.
                                Gateway2IT reserves the right to reject without assigning any reason, an online purchase made by a Customer having prior history of questionable charges including without limitation breach of any agreements by Customer with Gateway2IT or breach/violation of any law or any charges imposed by issuing bank or breach of any policy.
                                Gateway2IT may delay the notification of payment confirmation to a Customer if Gateway2IT deems the transaction suspicious or to a Customer conducting high transaction volumes for ensuring the safety of the transaction. In addition, Gateway2IT may hold the transaction and not inform the Customer or remit the purchase Amount to law enforcement officials (instead of refunding the same to the Customer) at the request of law enforcement officials or in the event the Customer is engaged in any form of illegal activity.
                                The Customer acknowledges that Gateway2IT will not be liable for any damages, interests, or claims etc. resulting from not processing a transaction or any delay in processing a transaction which is beyond the control of Gateway2IT.
                                Upon successful payment of the purchase Amount, the system will generate a transaction Reference Number, acknowledging the receipt of payment towards the purchase of the Subscription made online. However, this acknowledgment shall not be treated as acceptance of purchase by Gateway2IT.
                                After successful completion of online purchase, the Customer will get a purchase Reference ID. The Customer shall use the purchase Reference ID for all future communication with Gateway2IT and produce the copy of the receipt showing the purchase Reference ID as and when required.
                                Acceptance of purchase by Gateway2IT</div>

                            <div>The Customer agrees that Gateway2IT shall not be deemed to have accepted the purchase of the Customer or be bound by the purchase until Gateway2IT notifies the Customer of its acceptance of the purchase.
                                The purchase Amount paid by the Customer at the time of online purchase will be adjusted against the sale price of the Subscription at the time of raising the invoice by Gateway2IT. The Customer shall pay the balance amount to Gateway2IT to complete the successful transaction of purchase of the Subscription. Thereupon, Gateway2IT shall deliver the Subscription as per the delivery commitment date given by Gateway2IT. Only after receipt of the full balance price of the Subscription and submission of requisite supporting documents, the purchase will be binding. Until then, the online purchase is merely a request on the part of the Customer and an indication of an intention to sell on the part of Gateway2IT and does not result in a purchase confirmation or contract of sale. Should the purchase fail to be accepted for whatever reasons or could not be completed, there will be no financial implications on Gateway2IT except as per the terms and conditions mentioned here.
                                If a Customer fails to pay the balance amount within the stipulated time as informed by Gateway2IT, the Subscription chosen by the Customer may be allocated to the next customer. Thereafter, depending upon the availability of the Subscription, the Customer may be allotted the next Subscription. In the event, the Subscription is not available with Gateway2IT, the purchase Amount paid by the Customer shall be refunded to the Customer as per the terms mentioned herein.
                                Refund Policy for purchase Amount
                            </div>


                            <div>Gateway2IT shall have the right at any time to change or discontinue any aspect or feature of the Website, including, but not limited to, content, hours of availability, and equipment needed for access or use. Further, Gateway2IT may discontinue disseminating any portion of information or category of information, may change or eliminate any transmission method and may change transmission speeds or other signal characteristics. Gateway2IT reserves the right to refuse access to the Website, terminate Accounts, remove or edit content without any notice to You.</div>
                        </div>
                        <h3>Electronic Communication</h3>

                        <div> When You use the Website or send emails or other data, information or communication to Gateway2IT, You agree and understand that You are communicating with Gateway2IT through electronic records, and You consent to receive communications via electronic records from Gateway2IT periodically and as and when required. Gateway2IT will communicate with You by email or electronic records on the email address and/or mobile number available with Gateway2IT, which will be deemed adequate service of notice / electronic record to the maximum possible extent permitted under any applicable law, provided that You must be responsible for providing Gateway2IT with the correct email address and/or mobile number.</div>

                        <h3>Indemnification</h3>

                        <div>You agree to indemnify and hold Gateway2IT harmless from and against any and all claims, actions, liabilities, damages, costs, and expenses, including reasonable attorneys' fees, arising out of Your use of the Website or any alleged breach of these T&C.</div>

                        <h3>Modifications to These Terms and Conditions</h3>

                        <div> Gateway2IT reserves the right to change or modify these T&C at any time and will post the updated terms on the Website. Your continued use of the Website following any such changes shall signify Your acceptance of such updated terms.</div>

                    </div>
                </Paper>
            </Grid>


        </Grid>
    </>
}