
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';

export default function DatePiker({ props, increaseSQWithDelay }) {

    const today = dayjs();

    return <div className='outer-date-time-picker' key={props.sq}> <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
            <DatePicker
                label={props.lable}
                defaultValue={today}
                // disablePast
                onAccept={(date) => {
                    const selectedDate = date ? dayjs(date).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
                    increaseSQWithDelay(props, selectedDate, null);
                }} className='custom-datetime-picker' />
        </DemoContainer>
    </LocalizationProvider></div>
}