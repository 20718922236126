
import { Button, ButtonGroup } from '@mui/material';

export default function Confirmation({ props, increaseSQWithDelay }) {

    return <div className="Confirmation" key={props.sq}>
        <ButtonGroup >
            {props.options.map((item, index) => (
                <Button key={index} variant="contained" color={item.color}
                    onClick={() => increaseSQWithDelay(props,item.msg, item)}
                    fullWidth sx={{ 'margin-right': '10px' }}
                >
                    {item.msg}
                </Button>
            ))}

        </ButtonGroup>
    </div>
}