import Question from "./component/Question";
import Login from "./component/admin/auth/Login";
import OtpVerification from "./component/admin/auth/OtpVerification";
import SignUp from "./component/admin/auth/SignUp";
import AddNewQuestions from "./component/admin/component/Bot/Add/Questions/AddNewQuestions";
import Bot from "./component/admin/component/Bot/Bot";
import Dashbord from "./component/admin/component/Dashboard";
import Client from "./component/admin/component/client/Client";
import Profile from "./component/admin/component/client/Profile";
import Industry from './component/admin/component/industry/Industry';
import Language from "./component/admin/component/language/Language";
import Subscription from "./component/admin/component/subscription/Subscription";
import ChatBot from "./component/chatBot/ChatBot";

import Invoice from "./component/admin/component/Billing/Invoice";
import SubscriptionPurchase from "./component/admin/component/Billing/SubscriptionPurchase";
import Billing from "./component/admin/component/Billing/billing";
import BotConfiguration from "./component/admin/component/Bot/BotConfiguration";
import Document from "./component/admin/component/Document/Document";
import UserQueries from "./component/admin/report/UserQueries";
import TermAndCondition from "./component/web/TermAndCondition";
import Web from "./component/web/Web";
import { LINK } from "./utils/apiConfig";

export const AdminRouter = [
  {
    path: LINK.HOME,
    component: Dashbord,
    exact: true,
  },
  {
    path: LINK.BOTS,
    component: Bot,
    exact: true,
  },
  {
    path: '/' + LINK.BOTS_BY_CLIENT,
    component: Bot,
    exact: true,
  },
  {
    path: LINK.ADD_BOTS,
    component: BotConfiguration,
    exact: true,
  },
  {
    path: LINK.INDUSTRY,
    component: Industry,
    exact: true,
  },
  {
    path: LINK.LANGUAGE,
    component: Language,
    exact: true,
  },
  {
    path: LINK.CLIENT,
    component: Client,
    exact: true,
  },
  {
    path: LINK.SUBSCRIPTION,
    component: Subscription,
    exact: true,
  },
  {
    path: LINK.ADD_QUESTIONS,
    component: AddNewQuestions,
    exact: true,
  },
  {
    path: LINK.PROFILE,
    component: Profile,
    exact: true,
  },
  {
    path: LINK.USER_QUERY,
    component: UserQueries,
    exact: true,
  },
  {
    path: LINK.BILLING,
    component: Billing,
    exact: true,
  },
  {
    path: LINK.DOCUMENTS,
    component: Document,
    exact: true,
  },
  {
    path: "/invoice",
    component: Invoice,
    exact: true
  },
  {
    path: "/purchase",
    component: SubscriptionPurchase,
    exact: true
  }
];

export const MainRouter = [
  {
    path: LINK.SIGN_IN,
    component: Login,
    exact: true,
  },
  {
    path: LINK.SIGN_UP,
    component: SignUp,
    exact: true,
  },
  {
    path: LINK.OTP,
    component: OtpVerification,
    exact: true,
  },
  {
    path: '/' + LINK.BOT,
    component: ChatBot,
    exact: true,
  },
  {
    path: '/' + LINK.BOT + '/:id',
    component: ChatBot,
    exact: true,
  },
  {
    path: "/web",
    component: Web,
    exact: true,
  },
  {
    path: "/term&condition",
    component: TermAndCondition,
    exact: true
  }
];


export const BotRouter = [
  {
    path: "chat/:id",
    component: Question,
    exact: true,
  }
];

export const ChatRouter = [
  {
    path: "/",
    component: Web,
    exact: true,
  }
];
