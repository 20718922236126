import { CancelPresentation } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import ConfigService from "../../services/ConfigService";
import "./style.css";

export default function AddSubscription(props: any) {
  const [duration, setDuration] = useState("");
  const [branding, setBranding] = useState(true);
  const [planType, setPlanType] = useState("");

  const selectDuration = (event: SelectChangeEvent) => {
    setDuration(event.target.value as string);
  };

  const saveClient = async (event: React.FormEvent<HTMLFormElement> | any) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const name = formData.get("name") as string;
    const price = formData.get("price") as string;
    const noOfChats = formData.get("noOfChats") as string;
    const noOfVisitor = formData.get("noOfVisitor") as string;
    const noOfBot = formData.get("noOfBot") as string;
    let features = formData.get("features") as string;
    features = features.replace(/\n/g, '#$');

    if (!name.trim()) {
      toast.error("Please enter a name for the Subscription.");
      return;
    }
    if (!price.trim()) {
      toast.error("Please enter a Price for the Subscription.");
      return;
    }
    const data = {
      name,
      price,
      noOfChats,
      noOfVisitor,
      noOfBot,
      duration,
      features,
      branding,
      planType
    };

    try {
      const response = await ConfigService.saveSubscription(data);
      if (response.status === 200) {
        props.getData();
        props.onClose();
      }
      response.status === 200
        ? toast.success(response.data)
        : toast.error(response.data);
    } catch (error) {
      toast.error(name + " this Subscription is already created");
    }
  };

  const selectSubscriptionType = (event: SelectChangeEvent) => {
    setPlanType(event.target.value as string);
  };

  console.log(planType);
  
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        component="form"
        onSubmit={saveClient}
      >
        <DialogTitle>
          <h3>
            Add New Subscription
            <IconButton className="client-dailog-close" onClick={props.onClose}>
              <CancelPresentation />
            </IconButton>{" "}
          </h3>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="name"
                    label="Subscription Name"
                    name="name"
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="price"
                    label="Price"
                    name="price"
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="noOfChats"
                    label="No of Chats"
                    name="noOfChats"
                  />
                </Grid>

                <Grid item md={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="noOfVisitor"
                    label="No of Visitor"
                    name="noOfVisitor"
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="noOfBot"
                    label="No of Bots   "
                    name="noOfBot"
                  />
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth sx={{ marginTop: "15px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Duration
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={duration}
                      label="Duration"
                      onChange={selectDuration}
                    >
                      <MenuItem value={"Monthly"}>Monthly</MenuItem>
                      <MenuItem value={"Yearly"}>Yearly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth sx={{ marginTop: "15px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Subscription Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={planType}
                      label="Subscription Type"
                      onChange={selectSubscriptionType}
                    >
                      <MenuItem value={"Paid"}>Paid</MenuItem>
                      <MenuItem value={"Trial"}>Trial</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <InputLabel id="demo-simple-select-label">
                    GBot Branding
                  </InputLabel>
                  <div className="action-btn">
                    <div className="switch">
                      <Switch
                        checked={branding}
                        onChange={() =>
                          branding ? setBranding(false) : setBranding(true)
                        }
                        color="warning"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={12}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="features"
                    label="Features"
                    name="features"
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item md={12}>
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}
