// axiosInstance.js
import axios from 'axios';
import { API_ROUTES } from "../utils/apiConfig";
import LoginService from './LoginService';

let logoutHandler;
export const setLogoutHandler = (handler) => {
  logoutHandler = handler;
};

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {

    if (config.url === API_ROUTES.SIGN_IN) {
      return config;
    }

    const token = LoginService.getJwtToken();

    if (token) {
      config.headers = {
        ...config.headers,
        'Accept': 'application/json',
        'Authorization': `${token}`
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {

    return response;
  },
  async error => {
    if (error.response) {
      if (error.response.status === 504) {
        if (logoutHandler) {
          logoutHandler();
        }
      }

      if (error.response.status === 401) {
        if (logoutHandler) {
          // logoutHandler();
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
