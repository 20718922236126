import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function ReactSkeleton() {
  return (
    <Stack spacing={1} className='skeleton-container'>
      <Skeleton variant="rectangular" className='rectangular-skeleton' />
      <Skeleton variant="rounded" className='rounded-skeleton' />
      <Skeleton variant="rectangular" className='rectangular-skeleton' />
      <Skeleton variant="rounded" className='rounded-skeleton' />
      <Skeleton variant="rectangular" className='rectangular-skeleton' />
      <Skeleton variant="rounded" className='rounded-skeleton' />
      <Skeleton variant="rectangular" className='rectangular-skeleton' />
      <Skeleton variant="rounded" className='rounded-skeleton' />
      <Skeleton variant="rectangular" className='rectangular-skeleton' />
      <Skeleton variant="rounded" className='rounded-skeleton' />
      <Skeleton variant="rectangular" className='rectangular-skeleton' />
      <Skeleton variant="rounded" className='rounded-skeleton' />
      <Skeleton variant="rectangular" className='rectangular-skeleton' />
    </Stack>
  );
}
