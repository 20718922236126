import { Box } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/colors/Theme.css";
import Sidebar from "../component/admin/theme/Sidebar";
import LoginService from "../service/LoginService";
import { setLogoutHandler } from '../service/axiosInstance';

const AdminLayout = ({ children }: any) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    LoginService.doLogoutUser(navigate);
  };
  setLogoutHandler(handleLogout);

  
  useEffect(() => {    
    const delay = setTimeout(() => {
      LoginService.refreshToken();
    }, 1000);
    return () => clearTimeout(delay);
  });

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            padding: "80px 24px 0px 24px",
            minHeight: "99.7vh",
            backgroundColor: "azure",
          }}
        >
          {children}
        </Box>
      </Box>
      {/* <Footer /> */}
    </>
  );
};

export default AdminLayout;
