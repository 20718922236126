
import { DoneOutline } from '@mui/icons-material';
import Textarea from '@mui/joy/Textarea';
import IconButton from "@mui/material/IconButton";
import { useState } from 'react';

export default function LongText({ props, increaseSQWithDelay }) {

    const [longText, setLongText] = useState('');

    return <div>
        <Textarea
            minRows={5}
            maxRows={5}
            onChange={(e) => setLongText(e.target.value.replace(/\n/g, '#$').trim())}
            placeholder={props.lable}
        />
        {<div className='cb-proceed-Swiper'>
            <IconButton className={props.isSkipped ? 'cb-ok' : longText.trim() === '' ? 'not-selected' : 'cb-ok'}
                onClick={() => increaseSQWithDelay(props, longText, null)} sx={{ left: '23%' }}
                disabled={props.isSkipped ? false : longText.trim() === ''}>
                <DoneOutline />
            </IconButton>
        </div>}
    </div>
}