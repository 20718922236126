import { Check } from "@mui/icons-material";
import { Divider, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ConfigService from "../admin/services/ConfigService";
import "./Web_Style.css";


export default function Subscription({action}) {

    const [subscription, setSubscription] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await ConfigService.getAllSubscriptionByPaidPlan();
            if (response.status === 200) {
                setSubscription(response.data);
            } else {
                toast(response.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    return <Grid container spacing={2} style={{ padding: 20 }}>
        {subscription && subscription.map((item, index) =>
            <Grid item xs={12} sm={6} md={4} key={index}>
                <div className="pricing-col">
                    <div className="pricing-header">
                        <h2 className="pricing-name">{item.name}</h2>
                        <p className="pricing-title">
                            For growing companies with small teams
                        </p>
                        <p className="pricing-am">
                        &#8377; {item.price}<span>/Month<span style={{ color: 'red' }}>*</span> </span>
                        </p>
                        <p className="pricing-cycle">Billed Monthly</p>
                    </div>
                    <Divider />
                    <div className="pricing-body">
                        {item.features && item.features.split('#$').map((subItem, subIndex) =>
                            <p className="pricing-feature" key={subIndex}>
                                <Check sx={{ color: "#82b1ff" }} />{" "}
                                <span>{subItem}</span>
                            </p>
                        )}
                    </div>
                    <div className="pricing-footer">
                        <a className="create-btn contact-btn" onClick={action}>Start Free Trial</a>
                    </div>
                </div>
            </Grid>
        )}

    </Grid>
}