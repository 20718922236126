import { DoneOutline, Refresh } from '@mui/icons-material';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import Chip from '@mui/material/Chip';
import IconButton from "@mui/material/IconButton";
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from "react";

export default function MultiSelect({ props, increaseSQWithDelay }) {

    const [selectedMultiselect, setSelectedMultiselect] = useState([]);
    const [checkedState, setCheckedState] = useState({});
    const [isAnyChecked, setIsAnyChecked] = useState(false);

    useEffect(() => {
        setIsAnyChecked(Object.values(checkedState).some(value => value));
    }, [checkedState]);

    const handleUncheckAll = () => {
        setCheckedState(Array(checkedState.length).fill(false));
    };

    const handleCheckboxChange = (index, value) => {
        if (selectedMultiselect.some(item => item === value)) {
            setSelectedMultiselect(OldselectedMultiselect => OldselectedMultiselect.filter(item => item !== value));
        } else {
            setSelectedMultiselect(OldselectedMultiselect => [...OldselectedMultiselect, value]);
        }
        setCheckedState(prevState => {
            const newState = { ...prevState };
            newState[index] = !newState[index];
            return newState;
        });
    };

    return <FormGroup key={props.sq}>
        <div className='outer-checkbox-div'> {props.options.map((op, index) => (
            <div className="checkbox-div" key={index} onClick={() => handleCheckboxChange(index, op)}>
                <FormControlLabel
                    value={op.msg}
                    control={<Checkbox checked={checkedState[index] || false} />}
                    labelPlacement="end"
                    className='checkbox'
                />
                <Stack direction="row" spacing={1}>
                    <Chip label={op.msg} color='primary' className={`checkbox-chip ${checkedState[index] ? 'checked' : ''}`} key={index} />
                </Stack>
            </div>
        ))
        }</div>
        {<div className='cb-proceed'>
            <IconButton className={isAnyChecked.length === 0 ? 'not-selected' : 'cb-ok'}
                onClick={() => increaseSQWithDelay(props, selectedMultiselect)}
                disabled={!isAnyChecked}>

                <DoneOutline />
            </IconButton>
            <IconButton className={isAnyChecked.length === 0 ? 'not-selected' : 'cb-refresh'}
                onClick={handleUncheckAll}
                disabled={!isAnyChecked}>
                <Refresh />
            </IconButton>
        </div>
        }
    </FormGroup>
}