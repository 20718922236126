import * as React from 'react';
import "./Question.css";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import queTypes from './QueTypes';
import QueTooltip from './Tooltip';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import MoreAction from './MoreAction';
import Answer from './Answer';

function Question() {
  
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [typeId, setTypeId] = React.useState<HTMLButtonElement | null>(null);

  const showAnswer = (typeId:any) => {
    setTypeId(typeId);
    handleClose();
  }
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Grid container>
      <Grid item md={4}>
        <Paper className='que-pan'>
        <Button variant="outlined" className='add-new-btn' aria-describedby={id} onClick={handleClick}>Add New Question</Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography sx={{ p: 2 }} className='que-option-pan'>
              <Grid container className='que-option'>
              {queTypes.map((item, index) => (
                <Grid item key={index} onClick={() => showAnswer(item.typeId)}>
                  <QueTooltip btnText={item.btnText} icon={item.icon} title={item.title}/>
                </Grid>
              ))}
              </Grid>
            </Typography>
          </Popover>
          <Paper elevation={2} className='que'>
            <Checkbox size="small"/> Question-1
            <MoreAction id = {1} />
          </Paper>
          <Paper elevation={2} className='que' >
            <Checkbox size="small" /> Question-2
            <MoreAction id = {2} />
          </Paper>
          <Paper elevation={2} className='que' >
            <Checkbox size="small" /> Question-3
            <MoreAction id = {3} />
          </Paper>
          <Paper elevation={2} className='que' >
            <Checkbox size="small" /> Question-4
            <MoreAction id = {4}/>
          </Paper>
        </Paper>
      </Grid>
      <Grid item md={8}>
        <Grid container>
          <Grid item md={12}>
            <Paper className='ans-pan'>
              <p className='que-head'>Question Details</p>
              <Divider />
              <Grid container>
                <Grid item md={4} sx={{ padding: '10px' }}>
                  <TextField
                  id="name"
                  label="Question Name"
                  variant="outlined"
                  fullWidth 
                  size="small"
                />
              </Grid>
              <Grid item md={4} sx={{ padding: '10px' }}>
                <TextField
                  id="code"
                  label="Question Code"
                  variant="outlined"
                  fullWidth 
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item md={4} sx={{ padding: '10px' }}>
                <TextField
                  id="length"
                  label="Question Length"
                  type='number'
                  variant="outlined"
                  fullWidth 
                  size="small"
                  defaultValue="255"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={11} sx={{ padding: '10px' }}>
                <TextField
                  id="que"
                  label="Question Text"
                  fullWidth 
                  size="small"
                  multiline
                  maxRows={4}
                />
              </Grid>
              <Grid item md={1} sx={{ padding: '5px' }}><Paper className='que-lang'>EN</Paper></Grid>
            </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <Paper className='ans-pan'>
              <p className='que-head'>Answer Details</p>
              <Divider />
              <Answer typeId={typeId} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Question;