
import { useEffect, useState } from 'react';
import { environment } from '../utils/environments/environment';

export default function Home({ showChatBox, img, props }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mobileView, setMobileView] = useState(true);

    const updateWindowWidth = () => {
        setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener('resize', updateWindowWidth);
        if (!img && windowWidth < 500) {
            setMobileView(false)
        } else {
            setMobileView(true)
        }

    }, [img, windowWidth]);

    return <>
        {props && <div className='background-image' style={{ backgroundImage: `url(${environment.IMAGE_BASE_URL + props.themeSetting.backgroundPath})` }}></div>}
    </>
}