import { Grid } from "@mui/material";
import React from "react";
import './Style.css';

const LongText = () => {
    return(
        <>
            <Grid container>
                <Grid item md={12}>
                    <p className="long-text">There are no Answer options for this question type</p>
                </Grid>
            </Grid>
        </>
    );
}

export default LongText;