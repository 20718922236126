import { Grid } from "@mui/material";
import React from "react";
import './Style.css';

const NumberInput = () => {
    return(
        <>
            <Grid container>
                <Grid item md={12}>
                    <p className="short-text">NumberInput</p>
                </Grid>
            </Grid>
        </>
    );
}

export default NumberInput;