import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import QuestionService from '../../../../../../service/QuestionService';

export default function AddNewQuestions() {
    
    const [formData, setFormData] = useState({
        botId: '',
        language: '',
        questions: ''
    });

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = QuestionService.addQuestions(formData);

            console.log('Data sent successfully:', response.data);

            setFormData({
                botId: '',
                language: '',
                questions: ''
            });
        } catch (error) {
            console.error('Error sending data:', error);
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <TextField
                    required
                    id="botId"
                    label="Enter Bot Id"
                    value={formData.botId}
                    onChange={handleInputChange}
                />
                <TextField
                    required
                    id="language"
                    label="Enter Language"
                    value={formData.language}
                    onChange={handleInputChange}
                />
                <TextField
                    required
                    id="questions"
                    label="Enter Question"
                    value={formData.questions}
                    onChange={handleInputChange}
                />
            </div>
            <Button variant="contained" type='submit'>Submit</Button>
        </Box>
    );
}
