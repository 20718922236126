import { ChatBubbleOutline } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { TemplateData } from "../../assets/data/WebContent";
import "./Web_Style.css";

export default function PopularTemplate({action}) {

    const handleDemoClick = (url) => {
        window.open(url);
    }

    return <Grid container spacing={2}>
        {TemplateData.map((item, index) =>
            <Grid item sm={6} md={4} key={index}>
                <div className="template-card">
                    <div className="template-banner">
                        <img alt=""
                            src={require(`../../assets/images/web/template/${item.img}`)}
                        />
                    </div>
                    <div className="template-content">
                        <h3>{item.heading}</h3>
                        <p>{item.discription}</p>
                    </div>
                    <div className="template-action">
                        <Button variant="contained" className="template-btn" onClick={action}>Use this template</Button>
                        <Button
                            onClick={() => handleDemoClick(item.url)}
                            className="live-demo-btn"
                            variant="outlined"
                            style={{ marginLeft: 13 }}
                            startIcon={<ChatBubbleOutline />}
                        >
                            Live Demo
                        </Button>
                    </div>
                </div>
            </Grid>)}
    </Grid>
}