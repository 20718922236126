import { toast } from "react-toastify";
import { Token } from "../component/admin/model/Token";
import ClientService from "../component/admin/services/ClientService";
import { API_ROUTES } from "../utils/apiConfig";
import axiosInstance from "./axiosInstance";

export default class LoginService {
  
  static async signIn(userName: string, password: string) {
    try {
      const loginCredential = {
        userName,
        password,
      };
      
      const response = await axiosInstance.post(API_ROUTES.SIGN_IN, loginCredential);

      if (response.status !== 200) {
        toast(response.status +": "+ response.data.error);
        return false;
      } else {
         const data = await ClientService.getClientDetailsByUserName(userName);
         if (data.status !== 200) {
          toast(response.status +": "+ response.data.error);
          return false;
        } else { 
          LoginService.doLoginUser(data.data, response.data)
          return true
        }
      }
    } catch (error: any) {
      toast(error.response.status +": "+ error.response.data.error);
        return false;
    }
  }

  static async findStaffByUserName(username: string) {
    try {
      const response = await axiosInstance.get(`${API_ROUTES.AUTHENTICATION}/staff`, {
        params: { username },
      });
      return response.data;
    } catch (error: any) {
      return error.response;
    }
  }

  static async registration(data: any) {
    try {
      const response = await axiosInstance.post(`${API_ROUTES.CLIENT}/registration`,data);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  static async generateOTP(data: any) {
    try {
      const response = await axiosInstance.post(`${API_ROUTES.CLIENT}/regenerate-otp`,data);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  static async verifyOTP(data: any) {
    try {
      const response = await axiosInstance.post(`${API_ROUTES.CLIENT}/verify-otp`,data);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  static async refreshToken() {
    try {
      const response = await axiosInstance.get(API_ROUTES.REFRESH_TOKEN);
      LoginService.storeJwtToken(response.data.access_token);
    } catch (error: any) {
      return error.response;
    }
  }

  static getJwtToken() {
    return localStorage.getItem("JWT_TOKEN");
  }

  static doLoginUser(data: any, tokens: Token) { 
    const user = {
      name: data.name,
      email: data.emailId,
      role: data.role 
    }
    localStorage.setItem("currentUser", JSON.stringify(user));
    this.storeJwtToken(tokens.access_token);
  }
  static doLogoutUser(navigate: (path: string) => void) {
    localStorage.removeItem('currentUser');
    this.removeTokens();
    localStorage.clear();
    //toast("Session Expire, Please Login Again!");
    navigate('/login');
  }


  static storeJwtToken(Jwt: string) {
    localStorage.setItem("JWT_TOKEN", Jwt);
  }

  static removeTokens() {
    localStorage.removeItem("JWT_TOKEN");
  }
}
