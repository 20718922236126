import { DoneOutline } from '@mui/icons-material';
import { FormControlLabel } from '@mui/material';
import Chip from '@mui/material/Chip';
import IconButton from "@mui/material/IconButton";
import Stack from '@mui/material/Stack';
import React, { useState } from "react";

import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';


export default function SingleSelect({ props, increaseSQWithDelay }) {

    const [selectedSingleSelect, setSelectedSingleSelect] = useState('')

    return <FormControl className='single-select' key={props.sq}>
        <RadioGroup className='single-select-radio-group'>
            <div className='outer-radio-div-singleSelect'>
                {props.options.map((op, index) => (
                    <div className="radio-div" key={index} onClick={() => setSelectedSingleSelect(op)}>
                        <FormControlLabel
                            value={op.msg}
                            control={<Radio />}
                            labelPlacement="end"
                            label={op.msg}
                            className='radio'
                        />
                        {
                            <Stack direction="row" spacing={1}>
                                <Chip label={op.msg} color='primary'
                                    className={`radio-chip ${selectedSingleSelect && op.msg === selectedSingleSelect.msg ? 'selected' : ''}`} key={index} />
                            </Stack>
                        }
                    </div>
                ))
                }</div>
            {<div className='cb-proceed-singleSelect'>
                <IconButton className={selectedSingleSelect === '' ? 'not-selected' : 'cb-ok'}
                    onClick={() => increaseSQWithDelay(props, selectedSingleSelect.msg, selectedSingleSelect)} sx={{ left: '23%' }}
                    disabled={selectedSingleSelect === ''}>
                    <DoneOutline />
                </IconButton>
            </div>
            }
        </RadioGroup>
    </FormControl>
}