import { Grid } from "@mui/material";
import React from "react";
import './Style.css';

const Language = () => {
    return(
        <>
            <Grid container>
                <Grid item md={12}>
                    <p className="short-text">Language</p>
                </Grid>
            </Grid>
        </>
    );
}

export default Language;