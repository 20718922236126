import { Button, Grid } from "@mui/material";
import { ColorPickerLabelsAdmin } from "../../../../../assets/data/ThemeSettings";
import { ColorPickerLabelsClient } from "../../../../../assets/data/ThemeSettings";
import ColorPicker from "../../../../../utils/ColorPicker";
import FilePicker from "../../../../../utils/FilePicker";
import { useEffect, useState } from "react";
import { ROLE } from "../../../../../utils/apiConfig";

export default function ThemeSetting({
    themeSettings,
    setThemeSettings,
    saveThemeDetails }) {

    const role = JSON.parse(localStorage.getItem("currentUser") || "{}").role;
    const [currentLabel, setCurrentLabel] = useState('');

    useEffect(() => {
        if (role === ROLE.SUPER_ADMIN) {
            setCurrentLabel(ColorPickerLabelsAdmin);
        } else if (role === ROLE.CLIENT) {
            setCurrentLabel(ColorPickerLabelsClient)
        }
    }, [role]);

    const handleThemeSettings = (event, name) => {
        setThemeSettings(prevThemeSettings => ({
            ...prevThemeSettings,
            [name]: event.hex
        }));
    }

    const handelFilePicker = (event, name) => {
        setThemeSettings(prevThemeSettings => ({
            ...prevThemeSettings,
            [name]: event.target.files[0]
        }));
    }
    return (
        <Grid
            container
            spacing={2}
            component="form"
            sx={{ justifyContent: "center" }}
        >
            <Grid item md={10}>
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={6} md={6}>
                        <FilePicker
                            btnName="Upload Background Image"
                            handelFilePicker={handelFilePicker}
                            fieldName={"backgroundPath"} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} >
                        <FilePicker
                            btnName="Upload Logo"
                            handelFilePicker={handelFilePicker}
                            fieldName={"logoPath"} />
                    </Grid>
                    {currentLabel && currentLabel.map((item, index) =>
                        <Grid item xs={12} sm={6} md={6} key={index}>
                            <ColorPicker
                                handleThemeSettings={handleThemeSettings}
                                color={themeSettings[item.fieldName]}
                                label={item.label}
                                fieldName={item.fieldName} />
                        </Grid>)
                    }
                    <Grid item md={6} sx={{ marginTop: "3vh" }}>
                        <Button onClick={saveThemeDetails} variant="contained">Save</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}