import { Button, Divider, Grid, Paper, Switch } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SubscriptionTable } from "../../../../assets/data/tableConfig";
import ConfirmationDialog from "../../../../utils/ConfirmationDialog";
import SubscriptionData from "../../model/SubscriptionData";
import ConfigService from "../../services/ConfigService";
import '../style.css';
import AddSubscription from "./AddSubscription";

export default function Subscription() {
    const [rows, setRows] = useState<SubscriptionData[]>([]);
    const [open, setOpen] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [openSubscription, setOpenSubscription] = useState(false);

    const columns: GridColDef[] = SubscriptionTable.map((column) => ({
        ...column,
        ...(column.field === "action" && {
            renderCell: (params) => getRenderCell(params.row),
          }),
      }));
      
    useEffect(() => {
        fetchData();
      }, []);
    
      const fetchData = async () => {
        try {
          const response = await ConfigService.getAllSubscriptionDetails(true);
          if (response.status === 200) {
            const formattedData = response.data.map(
              (Subscription: SubscriptionData) => ({
                ...Subscription,
                active: Boolean(Subscription.active),
              })
            );
            setRows(formattedData);
          } else {
            toast(response.data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      const handleSwitchChange = async (rowData: any) => {
        const response = await ConfigService.archiveSubscription(rowData);
        if (response.status === 200) {
          fetchData();
          toast.success("Status Updated Successfully!");
        } else {
          toast.error("Unable to Change Status");
        }
      };
      
      const getRenderCell = (rowData: any) => {
        return (
          <div className="action-btn">
            <div className="switch">
              <Switch
                checked={rowData.active}
                onChange={() => handleDailogOpen(rowData)}
                color="warning"
              />
            </div>
          </div>
        );
      };

      const handleDailogOpen = (rowData: any) => {
        setRowData(rowData);
        setOpen(true);
      };
      const handleDailogClose = () => {
        setOpen(false);
      };
      const handleDailogConfirm = () => {
        handleSwitchChange(rowData);
        setOpen(false);
        setRowData(null);
      };

      const openAddSubscription = () => {
        setOpenSubscription(true);
      };
    
      const closeAddSubscription = () => {
        setOpenSubscription(false);
      };

      return (
        <>
            <Grid container spacing={2}>
              <Grid item md={12} className="panel-head-grid">
              <div className="panel-head-div">
                  <h3>Subscription Details</h3>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={openAddSubscription}
                  >
                    Add Subscription
                  </Button>
                  </div>
                  <Divider />
              </Grid>
                <Grid item md={12}>
                 <Paper style={{ width: '100%', overflowX: 'auto' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 10,
                            },
                        },
                        }}
                        pageSizeOptions={[5, 10, 25, 100]}
                    />
                 </Paper>
                </Grid>
                <ConfirmationDialog
                  open={open}
                  onClose={handleDailogClose}
                  onConfirm={handleDailogConfirm}
                  title="Confirmation"
                  message="Are you sure you want to Proceed?"
                />
                <AddSubscription
                  open={openSubscription}
                  onClose={closeAddSubscription}
                  getData={fetchData}
                />
            </Grid>
        </>
      );
}