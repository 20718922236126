import { Grid } from '@mui/material';
import generatePDF from 'react-to-pdf';
import './billing.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const options = {
    filename: "invoice.pdf",
    page: { margin: 1 }
};

export default function Invoice() {

    const navigate = useNavigate();
    const { state } = useLocation();
    const [counter, setCounter] = useState(10);
    const receivedData = state && state.data ? state.data : '';

    const downloadPdf = () => {
        const getTargetElement = () => document.getElementById("container");
        generatePDF(getTargetElement, options)
    };

    useEffect(() => {
        downloadPdf();

        const intervalId = setInterval(() => {
            setCounter(prevCounter => prevCounter - 1);
        }, 1000);

        const timeoutId = setTimeout(() => {
            navigate('/dashboard');
        }, 10000);

        return () => {
            clearInterval(intervalId);
            clearTimeout(timeoutId);
        };
    }, [navigate])

    return <>
        <div className='outer-container'>
            <div style={{ color: "#3ca3ef" }}>Redirected to home page in: {counter} </div>
            <div className='main-container' id='container'>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={12} sm={12} xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                        <h1>Gateway2IT</h1>
                        <span>Murari Chauk, Samneghat, varanasi</span>
                        <span>contact@gateway2it.com</span>
                        <span>1234567890</span>
                        <span>GSTNO: 545354fsdf4s354</span>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12} sx={{ direction: "rtl" }}>
                        <h3>Invoice </h3>
                        <p>{receivedData.invoiceNumber}</p>
                    </Grid>
                </Grid>
                <hr />
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                        <h1>Invoice to</h1>
                        <span>{receivedData.client.name}</span>
                        <span>{receivedData.client.address}</span>
                        <span>{receivedData.client.emailId}</span>
                        <span>{receivedData.client.contactNo}</span>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img style={{ width: "28%" }} src={require("../../../../assets/images/paid.png")} alt=''></img>

                    </Grid>
                </Grid>
                <table className='invoice-table'>
                    <thead>
                        <tr>
                            <th className='table-heading'>Description</th>
                            <th className='table-heading'>Duration</th>
                            <th className='table-heading' style={{ width: "25%" }}>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>{receivedData.subscription.name}</td>
                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>{receivedData.duration.name}</td>
                            <td className='invoice-price'>&#8377; {receivedData.itemPrice}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='table-items'>Sub Total</td>
                            <td className='invoice-price'>&#8377; {receivedData.itemPrice}</td>
                        </tr>
                        <tr><td></td>
                            <td className='table-items'>GST</td>
                            <td className='invoice-price'>&#8377; {receivedData.itemGST}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='table-items' >Total</td>
                            <td className='invoice-price table-items' style={{ fontSize: "x-large" }}>{receivedData.priceWithGST} &#8377;</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </>
}