import { Check } from "@mui/icons-material";
import { Button, Divider, Grid } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect, useState } from "react";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BillingService from "../../services/BillingService";
import ConfigService from "../../services/ConfigService";

const duration = [
    {
        name: "One Month (1)",
        value: 30
    },
    {
        name: "Three Month (3)",
        value: 90
    },
    {
        name: "Six Month (6)",
        value: 180
    },
    {
        name: "Twelve Month (12)",
        value: 360
    }
]

export default function SubscriptionPurchase() {

    const navigate = useNavigate();
    const [Razorpay] = useRazorpay();
    const [subscription, setSubscription] = useState('');
    const [currentSub, setCorrentSub] = useState('');
    const [currentDuration, setCurrentDuration] = useState("");
    const [currentPrice, setCurrentPrice] = useState(0);
    const [itemGST, setItemGST] = useState(0);
    const [priceWithGST, setPriceWithGST] = useState(0);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (currentDuration) {
            setCurrentPrice((currentDuration.value / 30) * currentSub.price);
            let gstAmount = currentPrice * 0.18;
            setItemGST(gstAmount.toFixed(2));
            setPriceWithGST(+currentPrice + +itemGST)
        }
    }, [currentDuration, currentPrice, currentSub, itemGST])

    const fetchData = async () => {
        try {
            const response = await ConfigService.getAllSubscriptionDetails('true');
            if (response.status === 200) {
                setSubscription(response.data);
                setCorrentSub(response.data[0])
                setCurrentDuration(duration[0])
            } else {
                toast(response.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const subscribeNow = async () => {
        if (currentSub.planType === "TRIAL") {
            const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}").email;
            try {
                const data = {
                    "client": {
                        "emailId": currentUser,
                    },
                    "subscription": {
                        "id": currentSub.id,
                    },
                    "duration": currentDuration.value,
                    "planType": currentSub.planType,
                    "orderId": "",
                    "razorPayOrderId": "",
                    "razorPayPaymentId": "",
                    "signature": ""
                }
                subscribeForClient(data)
            } catch (error) {
                console.error("Error fetching data:", error);
            }

        } else {
            try {
                const orderId = await BillingService.createOrder(priceWithGST);
                if (orderId.status === 200) {
                    const options = {
                        key: "rzp_test_OcA8HXp2cfSoxz",
                        name: "Gateway2IT",
                        description: "Test Transaction",
                        image: "https://bot.gateway2it.com/assets/images/gbot/bg.jpeg",
                        order_id: orderId.data,
                        handler: async (res) => {
                            const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}").email;
                            try {
                                const data = {
                                    "client": {
                                        "emailId": currentUser,
                                    },
                                    "subscription": {
                                        "id": currentSub.id,
                                    },
                                    "duration": currentDuration.value,
                                    "planType": currentSub.planType,
                                    "orderId": orderId.data,
                                    "razorPayOrderId": res.razorpay_order_id,
                                    "razorPayPaymentId": res.razorpay_payment_id,
                                    "signature": res.razorpay_signature
                                }
                                subscribeForClient(data);
                            } catch (error) {
                                console.error("Error fetching data:", error);
                            }
                        },
                        prefill: {
                            name: "CompanyName",
                            email: "youremail@example.com",
                            contact: "9999999999",
                        },
                        notes: {
                            address: "Razorpay Corporate Office",
                        },
                        theme: {
                            color: "#3399cc",
                        },
                    };

                    const rzpay = new Razorpay(options);
                    rzpay.open();
                } else {
                    toast(orderId.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };

    const handleChange = (event) => {
        setCorrentSub(event.target.value);
    };

    const handleDurationChange = (event) => {
        setCurrentDuration(event.target.value)
    }

    const subscribeForClient = async (data) => {
        const response = await BillingService.subscribeForClient(data);
        if (response.status === 200) {
            console.log(response.data);
            const invoice = {
                description: "You purchased successfully",
                itemPrice: currentPrice,
                itemGST: itemGST,
                priceWithGST: priceWithGST,
                invoiceNumber: response.data.invoiceNumber,
                subscription: currentSub,
                duration: currentDuration,
                client: response.data.client
            }

            if (currentSub.planType === "TRIAL") {
                navigate("/dashboard");
                toast("Purchase Sucessfully");
            } else {
                navigate("/invoice", { state: { data: invoice } });
                toast("Purchase Sucessfully");
            }
        } else {
            toast(response.data);
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item lg={8} md={8} >
                    <div className="billing-container">
                        <Grid container spacing={2}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Subscription</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={currentSub}
                                        label="Select Subscription"
                                        onChange={handleChange}
                                    >
                                        {subscription && subscription.map((item, index) =>
                                            <MenuItem value={item} key={index}>{item.name}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Select Duration</InputLabel>
                                    <Select
                                        disabled={currentSub.planType === "TRIAL"}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={currentDuration}
                                        label="Select Duration"
                                        onChange={handleDurationChange}
                                    >
                                        {duration.map((item, index) =>
                                            <MenuItem value={item} key={index}>{item.name}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                        <Grid container spacing={2} sx={{ justifyContent: "flex-end" }}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <table style={{ width: "100%", marginTop: "5%" }}>
                                    <thead>
                                        <tr>
                                            <th className='table-heading'>Description</th>
                                            <th className='table-heading'>Duration</th>
                                            <th className='table-heading' style={{ width: "25%" }}>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>{currentSub.name}</td>
                                            <td style={{ textAlign: "center", verticalAlign: "middle" }}>{currentDuration.name}</td>
                                            <td className='invoice-price'>&#8377; {currentPrice}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td className='table-items'>GST (18%)</td>
                                            <td className='invoice-price'>&#8377; {itemGST}  <span style={{ color: 'red' }}>*</span></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td className='table-items' >Total</td>
                                            <td className='invoice-price table-items' style={{ fontSize: "x-large" }}>
                                                {priceWithGST} &#8377;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>
                            <Grid item lg={1} md={2} sm={2} xs={3}>
                                <Button variant="contained" onClick={subscribeNow}>Pay</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item lg={4} md={4} >

                    <div className="billing-container">
                        <div className="pricing-header">
                            <h2 className="pricing-name">{currentSub.name}</h2>
                            <p className="pricing-title">
                                For growing companies with small teams
                            </p>
                            <p className="pricing-am">
                                {currentSub.price}<span>&#8377;/Month<span style={{ color: 'red' }}>*</span> </span>
                            </p>
                            <p className="pricing-cycle">Billed Monthly</p>
                        </div>
                        <Divider />
                        <div className="pricing-body">
                            {currentSub.features && currentSub.features.split('#$').map((subItem, subIndex) =>
                                <p className="pricing-feature" key={subIndex}>
                                    <Check sx={{ color: "#82b1ff" }} />{" "}
                                    <span>{subItem}</span>
                                </p>
                            )}
                        </div>

                        {/* <div className="pricing-footer">
                            {currentSub.planType === 'TRAIL' && <a className="create-btn contact-btn" onClick={() => subscribeNow(currentSub.id)}>Start Free Trial</a>}
                            {currentSub.planType === 'PAID' && <a className="create-btn contact-btn" onClick={() => subscribeNow(currentSub.id)}>Subscribe Now</a>}
                        </div> */}
                    </div>
                </Grid>
            </Grid>

        </>
    );
}