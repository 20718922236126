export const ColorPickerLabelsAdmin = [
    { label: "Pick Hover Text Color", fieldName: "hoverTextColor" },
    { label: "Pick Text Color", fieldName: "textColor" },
    { label: "Pick Background Color", fieldName: "backgroundColor" },
    { label: "Pick Border Color", fieldName: "borderColor" },
    { label: "Pick Hover Bg Color", fieldName: "hoverBackgroundColor" },
    { label: "Pick Correct Btn Color", fieldName: "correctButtonColor" },
    { label: "Pick Reset Button Color", fieldName: "resetButtonColor" },
    { label: "Pick Scroll bar Color", fieldName: "scrollBarColor" },
    { label: "Pick Hover Scroll bar Color", fieldName: "scrollBarHoverColor" },
    { label: "Pick Bot Bg Color", fieldName: "botBackgroundColor" },
    { label: "Pick Theme Color", fieldName: "themeColor" }
]

export const ColorPickerLabelsClient = [
    { label: "Pick Text Color", fieldName: "textColor" },
    { label: "Pick Correct Btn Color", fieldName: "correctButtonColor" },
    { label: "Pick Reset Button Color", fieldName: "resetButtonColor" },
    { label: "Pick Bot Bg Color", fieldName: "botBackgroundColor" },
    { label: "Pick Theme Color", fieldName: "themeColor" }
]
