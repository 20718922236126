import { useEffect, useState } from "react";

import CircularProgress from '@mui/material/CircularProgress';
export default function Exit({ props, handleExit }) {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (progress !== 150) {
            const timer = setInterval(() => {
                setProgress((prevProgress) => prevProgress + 1);
            }, 30);

            return () => {
                clearInterval(timer);
            };
        }

        if (progress === 150) {
            handleExit()
        }

    }, [handleExit, progress]);

   
    return <>
        {progress > 100 ?  <div className='exit-icon' key={props.sq}>
            <svg
                viewBox="0 0 24 24"
                width="100"
                height="100"
                fill="#00CC00"
                stroke="#FFFFFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-check-circle"
            >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M9 12l2 2 4-4"></path>
            </svg>
        </div>
            :
            <div className='exit-icon'>
                <CircularProgress variant="determinate" value={progress} />
                <p>Proccessing Your Data</p>
            </div>}

    </>
}


 