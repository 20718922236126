import { DoneOutline } from '@mui/icons-material';
import { FormControlLabel } from '@mui/material';
import Chip from '@mui/material/Chip';
import IconButton from "@mui/material/IconButton";
import Stack from '@mui/material/Stack';
import { useEffect, useState } from "react";

import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Test from '../../assets/data/Test';


export default function Language({ props, increaseSQWithDelay, setChatQue, data }) {

    const [lang, setLang] = useState('');

    useEffect(() => {
        const filteredQuestion = data.questions.find(item => item.language === lang.msg);
        
        if (filteredQuestion) {
            setChatQue(JSON.parse(filteredQuestion.questions));
            // setChatQue(Test);
        }

    }, [data, lang, setChatQue]);

    return <FormControl className='language-select' key={props.sq}>
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
        >
            <div className='outer-radio-div'> {props.options.map((op, index) => (
                <div className="radio-div" key={index} onClick={() => setLang(op)}>
                    <FormControlLabel
                        value={op.msg}
                        control={<Radio />}
                        labelPlacement="end"
                        label={op.msg}
                        className='radio'
                    />

                    <Stack direction="row" spacing={1}>
                        <Chip label={op.msg} color='primary' className={`radio-chip ${op === lang ? 'selected' : ''}`} key={index} />
                    </Stack>

                </div>
            ))
            }</div>
            {<div className='cb-proceed'>
                <IconButton className={lang === '' ? 'not-selected' : 'cb-ok'}
                    onClick={() => increaseSQWithDelay(props, lang.msg, lang)} sx={{ left: '23%' }}
                    disabled={lang === ''}>
                    <DoneOutline />
                </IconButton>
            </div>
            }
        </RadioGroup>
    </FormControl>
}