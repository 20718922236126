import TopHeader from "../component/TopHeader";

const BotLayout = ({children}: any) => {
  return (
    <div className="App">
       <TopHeader />
       {children}
    </div>
  );
};

export default BotLayout;
