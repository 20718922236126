import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import * as React from 'react';
import Answer from '../../Answer';

export default function ChatBotDailog({ currentEnquiry, showDialog, setShowDialog, botConfig }) {
    const [open, setOpen] = React.useState(showDialog);
    const themeSetting = botConfig.themeSetting

    const handleClose = () => {
        setOpen(false);
        setShowDialog(false)
    };

    return (
        <>
            <Dialog className='bot-dialog-container'
                style={{
                    "--text-color": themeSetting.textColor,
                    "--border-color": themeSetting.borderColor,
                    "--primary-color": themeSetting.themeColor,
                    "--scroll-bar-color": themeSetting.scrollBarColor,
                    "--hover-text-color": themeSetting.hoverTextColor,
                    "--background-color": themeSetting.backgroundColor,
                    "--reset-button-color": themeSetting.resetButtonColor,
                    "--bot-background-color": themeSetting.botBackgroundColor,
                    "--correct-button-color": themeSetting.correctButtonColor,
                    "--scroll-bar-hover-color": themeSetting.scrollBarHoverColor,
                    '--hover-background-color': themeSetting.hoverBackgroundColor,
                }}
                open={open}
                onClose={handleClose}>
                <DialogContent>
                    <Grid container >
                        {<div className='chat-box2' >
                            <div className='content-dialog'>
                                {currentEnquiry.map((m, index) =>
                                    <div key={index}>
                                        {m.msg.map((item, itemIndex) =>
                                        (item.msg === null ? '' :
                                            <div className='chat-que-dialog' key={itemIndex}>
                                                <div> {item.msg}</div>
                                            </div>)
                                        )}
                                        {(m.ans.msg === null || m.ans.msg === '') ? '' : <Answer data={m} index={index} />}
                                    </div>)}
                            </div>
                        </div>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
