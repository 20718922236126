const Test = [
  {
    "sq": 1,
    "ansType": "Language",
    "ansTypeId": "1",
    "options": [
      {
        "optionType": "Text",
        "optionTypeId": "1",
        "msg": "ENGLISH",
        "nextQueSq": 2
      },
      {
        "optionType": "Text",
        "optionTypeId": "1",
        "msg": "HINDI",
        "nextQueSq": 2
      }
    ],
    "msg": [
      {
        "msg": "Please select your language"
      }
    ],
    "nextQueSq": null,
    "min": "",
    "max": "",
    "ans": {
      "msg": "ENGLISH",
      "data": {
        "optionType": "Text",
        "optionTypeId": "1",
        "msg": "ENGLISH",
        "nextQueSq": 2
      }
    }
  },
  {
    "sq": 2,
    "ansType": "Start",
    "ansTypeId": "1",
    "options": null,
    "msg": [
      {
        "msg": "Hello, welcome to GBot"
      }
    ],
    "nextQueSq": 3,
    "min": "",
    "max": "",
    "ans": {
      "msg": null,
      "data": null
    }
  },
  {
    "sq": 3,
    "msg": [
      {
        "msg": "Write name of your business"
      }
    ],
    "ansType": "ShortText",
    "ansTypeId": "3",
    "lable": "Business name",
    "options": null,
    "nextQueSq": 4,
    "min": "",
    "max": "",
    "ans": {
      "msg": "Travel",
      "data": null
    }
  },
  {
    "sq": 4,
    "msg": [
      {
        "msg": "Please select your plan"
      }
    ],
    "ansType": "Swiper",
    "ansTypeId": 11,
    "options": [
      {
        "optionType": "Text",
        "optionTypeId": "1",
        "msg1": "Basic",
        "msg2": "Chats: 1000",
        "msg3": "399/Month",
        "img": null,
        "nextQueSq": 5
      },
      {
        "optionType": "Text",
        "optionTypeId": "2",
        "msg1": "Standard",
        "msg2": "Chats: 3000",
        "msg3": "999/Month",
        "img": null,
        "nextQueSq": 5
      },
      {
        "optionType": "Text",
        "optionTypeId": "3",
        "msg1": "Premium",
        "msg2": "Chats: Unlimited",
        "msg3": "2499/Month",
        "img": null,
        "nextQueSq": 5
      }
    ],
    "service": null,
    "nextQueSq": 10,
    "min": "",
    "max": "",
    "ans": {
      "msg": null,
      "data": null
    }
  }
]
;

export default Test;