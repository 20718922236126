import { Grid } from "@mui/material";
import React from "react";
import './Style.css';

const Calendar = () => {
    return(
        <>
            <Grid container>
                <Grid item md={12}>
                    <p className="short-text">Calendar</p>
                </Grid>
            </Grid>
        </>
    );
}

export default Calendar;