import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Chip, Grid, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import { useEffect, useState } from "react";
import { MenuOptions, botType } from "../../../assets/data/WebContent";
import { ROLE } from "../../../utils/apiConfig";
import BillingService from "../services/BillingService";
import InfoCard from "./InfoCard";
import TickPlacementBars from "./TickPlacementBars";

const label = { inputProps: { "aria-label": "Switch demo" } };

export default function DesktopView({
    role,
    rows,
    handleSwitch,
    handleReportClick
}) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [isMonthWise, setIsMonthWise] = useState(true);

    const [showCurrentSubcription, setShowCurrentSubcription] = useState(true);

    const [monthWiseDataSet, setMonthWiseDataSet] = useState([
        { data: 0, month: 'Jan' },
        { data: 0, month: 'Feb' },
        { data: 0, month: 'Mar' },
        { data: 0, month: 'Apr' },
        { data: 0, month: 'May' },
        { data: 0, month: 'Jun' },
        { data: 0, month: 'Jul' },
        { data: 0, month: 'Aug' },
        { data: 0, month: 'Sep' },
        { data: 0, month: 'Oct' },
        { data: 0, month: 'Nov' },
        { data: 0, month: 'Dec' },
    ]);

    const [dateWiseDataSet, setDateWiseDataSet] = useState([
        { data: 0, date: "1" },
        { data: 0, date: "2" },
        { data: 0, date: "3" },
        { data: 0, date: "4" },
        { data: 0, date: "5" },
        { data: 0, date: "6" },
        { data: 0, date: "7" },
        { data: 0, date: "8" },
        { data: 0, date: "9" },
        { data: 0, date: "10" },
        { data: 0, date: "11" },
        { data: 0, date: "12" },
        { data: 0, date: "13" },
        { data: 0, date: "14" },
        { data: 0, date: "15" },
        { data: 0, date: "16" },
        { data: 0, date: "17" },
        { data: 0, date: "18" },
        { data: 0, date: "19" },
        { data: 0, date: "20" },
        { data: 0, date: "21" },
        { data: 0, date: "22" },
        { data: 0, date: "23" },
        { data: 0, date: "24" },
        { data: 0, date: "25" },
        { data: 0, date: "26" },
        { data: 0, date: "27" },
        { data: 0, date: "28" },
        { data: 0, date: "29" },
        { data: 0, date: "30" },
        { data: 0, date: "31" }
    ]);

    const [chatAndVisitor, setChatAndVisitor] = useState({
        usedChat: 0,
        totalChat: 0,
        usedVisitor: 0,
        totalVisitor: 0

    })

    const [overallChatAndVisitor, setOverallChatAndVisitor] = useState({
        usedChat: 0,
        totalChat: 0,
        usedVisitor: 0,
        totalVisitor: 0

    })

    const handleClick = (event, item) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEl(null);
    };

    const handleMenuClick = (menu) => {
        setAnchorEl(null);
    };

    const previlege = (verified) => {
        if (role === ROLE.SUPER_ADMIN) {
            return true;

        } else if (role === ROLE.CLIENT) {
            if (verified) {
                return true;
            } else {
                return false;
            }
        }

        return false
    }

    const fetchData = async () => {
        try {
            const response = await BillingService.getClientCurrentBilling(rows[0].client.billingId);

            if (response.status !== 200) {
                throw new Error("Failed to fetch billing data");
            }

            const { noOfVisitor, noOfChats, noOfUsedChats, noOfUsedVisitor } = response.data;
            const aggregateData = (dataArray, key) => {
                return dataArray.reduce((map, item) => {
                    Object.entries(item[key]).forEach(([timeUnit, value]) => {
                        map.set(timeUnit, (map.get(timeUnit) || 0) + value);
                    });
                    return map;
                }, new Map());
            };

            const updateDataSet = (dataSet, aggregatedMap, key) => {
                return dataSet.map(({ [key]: timeUnit, data }) => ({
                    [key]: timeUnit,
                    data: aggregatedMap.get(timeUnit) || data,
                }));
            };

            const monthWiseAggregatedMap = aggregateData(rows, 'monthWiseChats');
            const updatedMonthWiseDataSet = updateDataSet(monthWiseDataSet, monthWiseAggregatedMap, 'month');
            setMonthWiseDataSet(updatedMonthWiseDataSet);

            const dayWiseAggregatedMap = aggregateData(rows, 'dayWiseChats');
            const updatedDayWiseDataSet = updateDataSet(dateWiseDataSet, dayWiseAggregatedMap, 'date');
            setDateWiseDataSet(updatedDayWiseDataSet);

            // const totalVisitorsUsed = rows.reduce((acc, item) => acc + item.noOfVisitor, 0);
            // const totalChatsUsed = rows.reduce((acc, item) => acc + item.usedChat, 0);

            setChatAndVisitor({
                totalVisitor: noOfVisitor,
                usedVisitor: noOfUsedVisitor,
                totalChat: noOfChats,
                usedChat: noOfUsedChats,
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (rows.length > 0) {
            fetchData();
        }
    }, [rows])

    const fetchAllSubscription = async () => {
        try {
          const response = await BillingService.getClientAllBillings(rows[0].client.id);
          if (response.status === 200) {

            const validSubscriptions = response.data.filter(subscription => !subscription.pending);
    
            const totalChats = validSubscriptions.reduce((sum, subscription) => sum + subscription.noOfChats, 0);
            const totalUsedChats = validSubscriptions.reduce((sum, subscription) => sum + subscription.noOfUsedChats, 0);
            const totalVisitor = validSubscriptions.reduce((sum, subscription) => sum + subscription.noOfVisitor, 0);
            const totalUsedVisitor = validSubscriptions.reduce((sum, subscription) => sum + subscription.noOfUsedVisitor, 0);

            setOverallChatAndVisitor({
                totalVisitor: totalVisitor,
                usedVisitor: totalUsedVisitor,
                totalChat: totalChats,
                usedChat: totalUsedChats,
            });
    
          }
        } catch (error) {
          console.error("Error fetching subscriptions:", error);
        }
      };
      

    useEffect(() => {
        if (!showCurrentSubcription) {
            fetchAllSubscription()
        }
    },[showCurrentSubcription])

const handleUrlClick = (item) => {
    window.open('/chat/' + item.endPoint)
}
    return <>
        {role === ROLE.CLIENT &&
            <Paper className="counter" sx={{ boxShadow: "rgba(71, 143, 243, 0.25) 0px 2px 5px -1px, rgba(51, 143, 255, 0.3) 0px 1px 3px -1px", padding: 2, borderRadius: 3 }}>
                <Chip
                    sx={{
                        padding: 2,
                        color: showCurrentSubcription ? "#02b2af" : "#1976d2",
                        fontWeight: "700",
                        margin: 1
                    }}
                    label="Current Subscription"
                    variant="outlined"
                    onClick={() => setShowCurrentSubcription(true)}
                />
                <Chip
                    sx={{
                        padding: 2,
                        color: showCurrentSubcription ? "#1976d2" : "#02b2af",
                        fontWeight: "700",
                        margin: 1
                    }}
                    label="Overall Subscription"
                    variant="outlined"
                    onClick={() => setShowCurrentSubcription(false)}
                />

                <InfoCard props={showCurrentSubcription ? chatAndVisitor : overallChatAndVisitor} />
            </Paper>}
        <div className="dashboard-container">
            {rows.map((item, index) => (
                <div className="dashboard-content" key={index}>
                    <div className="dashboard-content-inner-left">
                        <div className="inner-left-content">
                            {previlege(item.verified) ? <Switch {...label} onClick={() => handleSwitch(item)} checked={item.verified} disabled={item.verified} /> : <div> &nbsp;&nbsp;&nbsp;&nbsp; </div>}
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span>{item.name}</span>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-content-inner-right">
                        <div className="inner-right-content">
                            <div className="right-content-items">
                                {item.type === botType[1] && <OpenInNewIcon onClick={() => handleUrlClick(item)} /> }
                            </div>
                            <div className="right-content-items">
                                Completed <span>{item.usedChat}</span>
                            </div>
                            <div className="right-content-items">
                                Opens <span>{item.noOfVisitor}</span>
                            </div>
                            <div className="right-content-items">
                                Time to Finish <span>{item.timeToFinish}</span>
                            </div>
                            <div className="right-content-items">
                                <Chip
                                    sx={{ padding: 2 }}
                                    label="Report"
                                    variant="outlined"
                                    onClick={() => handleReportClick(item)}
                                />
                            </div>
                            <div className="right-content-items">
                                {previlege(item.verified) && <div>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={open ? "long-menu" : undefined}
                                        aria-expanded={open ? "true" : undefined}
                                        aria-haspopup="true"
                                        onClick={(event) => handleClick(event, item)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            "aria-labelledby": "long-button",
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                    >
                                        {MenuOptions.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                onClick={() => handleMenuClick(option)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>

        <Paper className="counter" sx={{ boxShadow: "rgba(71, 143, 243, 0.25) 0px 2px 5px -1px, rgba(51, 143, 255, 0.3) 0px 1px 3px -1px", padding: 2, borderRadius: 3 }}>
            <Chip
                sx={{
                    padding: 2,
                    color: isMonthWise ? "#02b2af" : "#1976d2",
                    fontWeight: "700",
                    margin: 1
                }}
                label="Month Wise Report"
                variant="outlined"
                onClick={() => setIsMonthWise(true)}
            />
            <Chip
                sx={{
                    padding: 2,
                    color: isMonthWise ? "#1976d2" : "#02b2af",
                    fontWeight: "700",
                    margin: 1
                }}
                label="Date Wise Report"
                variant="outlined"
                onClick={() => setIsMonthWise(false)}
            />
            <Grid container>
                {
                    isMonthWise ? <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TickPlacementBars dataset={monthWiseDataSet} label={"Total Chats"} dataKey={"month"} />
                    </Grid> : <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TickPlacementBars dataset={dateWiseDataSet} label={"Total Chats"} dataKey={"date"} />
                    </Grid>
                }
            </Grid>
        </Paper>

    </>
}