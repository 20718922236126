import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginService from "../../../service/LoginService";
import "./styles.css";

const Login = () => {

  useEffect(() => {
    const user = localStorage.getItem("currentUser");
    if(user !== null){
      dashboard();
    }
  }, []);
  
  const dashboard = () =>{
    navigate("/dashboard");
  }

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (formData.username.trim() === "") {
      setErrors({ ...errors, username: "Username is required" });
      return;
    }

    if (formData.password.trim() === "") {
      setErrors({ ...errors, password: "Password is required" });
      return;
    }
    
    const response = await LoginService.signIn(
      formData.username,
      formData.password
    );
    
    if (response) {
      toast("Login Successfully");
      dashboard();
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const signUp = () => {
    navigate("/registration");
  };

  return (
  <>
    <Grid container spacing={2}>
    <Grid item sm={1} md={2}></Grid>
      <Grid item sm={10} md={8} className="login-pan">
          <Paper className="login-pan-paper">
          <Grid container>
            <Grid item sm={6} md={6} className="login-side-pan">
              <img src={require('../../../assets/images/auth/auth.png')} style={{width: '75%'}} alt=""/>
            </Grid>
            <Grid item sm={6} md={6} className="login-form-pan">
              <span className="not-member">Not a member? <span onClick={signUp}>Register Now</span></span><br/>
              <Grid container>
                <Grid item md={1}></Grid>
                <Grid item md={10}>
                <h3 className="login-hel">Hello, Welcome Back!</h3>
              <Box
                className="form"
                component="form"
                onSubmit={handleSubmit}
                noValidate
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="User Name"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  onChange={handleChange}
                  error={!!errors.username}
                />

                <FormControl fullWidth required variant="outlined" className="password-form">
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    id="password"
                    label="Password"
                    name="password"
                    required
                    onChange={handleChange}
                    error={!!errors.password}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Grid container spacing={2} sx={{margin: 0}}>
               <Grid item md={12}>
                <span className="login-forget">Forget Password?</span>
               </Grid>
              </Grid><br/>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    className="login-btn"
                    >
                      Sign In
                    </Button>
                  </Grid>
                </Grid>
                {/* <Grid container>
                  <Grid item md={12}>
                      <p className="con-label">or continue with</p>
                      <img src={require('../../../assets/images/auth/google-btn.png')} className="g-btn" alt=""/>
                  </Grid>
                </Grid> */}
              </Box>
                </Grid>
              </Grid>
            </Grid>
            </Grid>
          </Paper>
      </Grid>
    </Grid>
    </>
  );
};

export default Login;

