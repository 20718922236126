import { Button, Divider, Grid, Paper, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ClientTable2 } from "../../../../assets/data/tableConfig";
import ConfirmationDialog from "../../../../utils/ConfirmationDialog";
import ReactTable from "../../../../utils/ReactTable";
import ClientData from "../../model/ClientData";
import ClientService from "../../services/ClientService";
import "../style.css";
import AddClient from "./AddClient";

export default function Client() {
  const [data, setData] = useState<ClientData[]>([]);

  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [openClient, setOpenClient] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await ClientService.getAllClientDetails();
      if (response.status === 200) {
        const formattedData = response.data.map((Client: ClientData) => ({
          ...Client,
          active: Boolean(Client.active),
          action: getRenderCell(Client),
        }));
        setData(formattedData);
      } else {
        toast(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSwitchChange = async (rowData: any) => {
    const response = await ClientService.archiveClient(rowData);
    if (response.status === 200) {
      fetchData();
      toast.success("Status Updated Successfully!");
    } else {
      toast.error("Unable to Change Status");
    }
  };

  const getRenderCell = (rowData: any) => {
    return (
      <div className="action-btn">
        <div className="switch">
          <Switch
            checked={rowData.active}
            onChange={() => handleDailogOpen(rowData)}
            color="warning"
          />
        </div>
      </div>
    );
  };

  const handleDailogOpen = (rowData: any) => {
    setRowData(rowData);
    setOpen(true);
  };
  const handleDailogClose = () => {
    setOpen(false);
  };
  const handleDailogConfirm = () => {
    handleSwitchChange(rowData);
    setOpen(false);
    setRowData(null);
  };

  const openAddClient = () => {
    setOpenClient(true);
  };

  const closeAddClient = () => {
    setOpenClient(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} className="panel-head-grid">
          <div className="panel-head-div">
            <h3>Client Details</h3>
            <Button type="submit" variant="contained" onClick={openAddClient}>
              Add Client
            </Button>
          </div>
          <Divider />
        </Grid>
        <Grid item md={12}>
          <Paper style={{ width: "100%", overflowX: "auto" }}>
            <ReactTable columns={ClientTable2} data={data} />
          </Paper>
        </Grid>
        <ConfirmationDialog
          open={open}
          onClose={handleDailogClose}
          onConfirm={handleDailogConfirm}
          title="Confirmation"
          message="Are you sure you want to Proceed?"
        />

        <AddClient
          open={openClient}
          onClose={closeAddClient}
          getData={fetchData}
        />
      </Grid>
    </>
  );
}
