import axiosInstance from "../../../service/axiosInstance";
import { API_ROUTES } from "../../../utils/apiConfig";

export default class ClientService {

    static async getAllClientDetails() {
        try {
          const response = await axiosInstance.get(API_ROUTES.CLIENT);
          return response;
        } catch (error: any) {
            return error.response;
        }
      }

      static async saveClient(data: any) {
        try {
          const response = await axiosInstance.post(`${API_ROUTES.CLIENT}`,data);
          return response;
        } catch (error: any) {
          return error.response;
        }
      }

      static async updateClient(data: any) {
        try {
          const response = await axiosInstance.post(`${API_ROUTES.CLIENT}/update`,data);
          return response;
        } catch (error: any) {
          return error.response;
        }
      }

      static async archiveClient(data: any) {
        try {
          const response = await axiosInstance.post(`${API_ROUTES.CLIENT}/archive`,data);
          return response;
        } catch (error: any) {
          return error.response;
        }
      }

      static async getClientDetailsByUserName(userName: string) {
        try {
          const response = await axiosInstance.get(`${API_ROUTES.CLIENT}/userName?userName=`+userName);
          return response;
        } catch (error: any) {
            return error.response;
        }
      }
}