import { Box, Button, Divider, Grid, Paper, Switch, TextField } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IndustryTable } from "../../../../assets/data/tableConfig";
import ConfirmationDialog from "../../../../utils/ConfirmationDialog";
import IndustryData from "../../model/IndustryData";
import ConfigService from "../../services/ConfigService";
import '../style.css';

export default function Industry() {
    const [rows, setRows] = useState<IndustryData[]>([]);
    const [open, setOpen] = useState(false);
    const [rowData, setRowData] = useState(null);

    const columns: GridColDef[] = IndustryTable.map((column) => ({
        ...column,
        ...(column.field === "action" && {
            renderCell: (params) => getRenderCell(params.row),
          }),
      }));
      
    useEffect(() => {
        fetchData();
      }, []);
    
      const fetchData = async () => {
        try {
          const response = await ConfigService.getAllIndustryDetails();
          if (response.status === 200) {
            const formattedData = response.data.map(
              (industry: IndustryData) => ({
                ...industry,
                active: Boolean(industry.active),
              })
            );
            setRows(formattedData);
          } else {
            toast(response.data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      const handleSwitchChange = async (rowData: any) => {
        const response = await ConfigService.updateIndustry(rowData);
        if (response.status === 200) {
          toast.success("Status Updated Successfully!");
          fetchData();
        } else {
          toast.error("Unable to Change Status");
        }
      };
      
      const getRenderCell = (rowData: any) => {
        return (
          <div className="action-btn">
            <div className="switch">
              <Switch
                checked={rowData.active}
                onChange={() => handleDailogOpen(rowData)}
                color="warning"
              />
            </div>
          </div>
        );
      };

      const saveIndustry = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const name = formData.get("name") as string;
        if (!name.trim()) {
            toast.error("Please enter a name for the industry.");
            return;
        }
        const data = {
            name
        }
        try {
          const response = await ConfigService.saveIndustry(data);
          if (response.status === 200) {
            fetchData();
          }
          response.status === 200
            ? toast.success(response.data)
            : toast.error(response.data);
        } catch (error) {
          toast.error("An error occurred while adding department.");
        }
      };

    const handleDailogOpen = (rowData: any) => {
      setRowData(rowData);
      setOpen(true);
    };
    const handleDailogClose = () => {
      setOpen(false);
    };
    const handleDailogConfirm = () => {
      handleSwitchChange(rowData);
      setOpen(false);
      setRowData(null);
    };
  
      return (
        <>
            <Grid container spacing={2}>
            <Grid item md={12} className="panel-head-grid">
                <h3>Industry Details</h3>
                <Divider />
            </Grid>
            
                <Grid item md={6}>
                 <Paper style={{ padding: '20px',maxHeight: 580, }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 10,
                            },
                        },
                        }}
                        pageSizeOptions={[5, 10, 25, 100]}
                    />
                 </Paper>
                </Grid>
                <ConfirmationDialog
                  open={open}
                  onClose={handleDailogClose}
                  onConfirm={handleDailogConfirm}
                  title="Confirmation"
                  message="Are you sure you want to Proceed?"
                />
                <Grid item md={6}>
                 <Paper style={{ padding: '20px'}}>
                    <div className="title-bar">Add Industry</div>
                    <Divider />
                    <Box
                    component="form"
                    onSubmit={saveIndustry}
                    noValidate
                    sx={{ width: 1 }}
                    >
                    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                        <Box gridColumn="span 12" marginRight={1}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Industry Name"
                                name="name"
                            />
                        </Box>
                        <Box
                            gridColumn="span 12"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            sx={{ width: "30%", minWidth: "100px", maxWidth: "129px" }}
                        >
                            SAVE
                        </Button>
                        </Box>
                    </Box>
                    </Box>
                 </Paper>
                </Grid>
            </Grid>
        </>
      );
}