import { DoneOutline } from '@mui/icons-material';
import IconButton from "@mui/material/IconButton";
import React, { useState } from 'react';

const Rating = ({ props, increaseSQWithDelay }) => {
    const [rating, setRating] = useState('');

    const handleStarClick = (selectedRating) => {
        setRating(selectedRating);
    };

    return (
        <div >
            <div className='rating-container'> {[...Array(Math.min(props.max))].map((_, index) => {
                const starNumber = index + 1;
                return (
                    <span className='star'
                        key={starNumber}
                        style={{ cursor: 'pointer', color: starNumber <= rating ? 'gold' : 'gray' }}
                        onClick={() => handleStarClick(starNumber)}
                    >
                        ★
                    </span>
                );
            })}</div>
            {
                <div className='cb-proceed-Swiper'>
                    <IconButton className={props.isSkipped ? 'cb-ok' : rating === '' ? 'not-selected' : 'cb-ok'}
                        onClick={() => increaseSQWithDelay(props, rating, null)} sx={{ left: '23%' }}
                        disabled={props.isSkipped ? false : rating === ''}>
                        <DoneOutline />
                    </IconButton>
                </div>
            }
        </div>
    );
};

export default Rating;
