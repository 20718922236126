import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { billingTable } from "../../../../assets/data/tableConfig";
import ReactTable from "../../../../utils/ReactTable";
import BillingService from "../../services/BillingService";
import CircleIcon from '@mui/icons-material/Circle';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import "./billing.css"

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        marginRight: "-5px",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

export default function Billing() {

    const navigate = useNavigate()
    const [tableData, setTableData] = useState('');
    const clientId = JSON.parse(localStorage.getItem("currentUser") || "{}").email;

    const getStatus = (item) => {
        if (item.active) return <div className="active-status status-button"> <StyledBadge variant="dot" /> <span style={{ padding: 2, marginLeft: 15 }}>Active</span></div>;
        if (item.pending) return <div className="pending-status status-button"><CircleIcon sx={{ width: 10, marginRight: 1 }} /> <span>Pending</span></div>;
        if (item.expired) return <div className="expired-status status-button"><CircleIcon sx={{ width: 10, marginRight: 1 }} /> <span>Expire</span></div>;
        return null;
    };

    const fetchData = async () => {
        try {
            const response = await BillingService.getClientAllBillingsByEmailId(clientId);
            if (response.status === 200) {
                const data = response.data.map(item => ({
                    status: getStatus(item),
                    noOfChats: item.noOfChats === -1 ? 'Unlimited' : item.noOfChats,
                    noOfUsedChats: item.noOfUsedChats,
                    noOfUsedVisitor: item.noOfUsedVisitor,
                    noOfVisitor: item.noOfVisitor === -1 ? 'Unlimited' : item.noOfVisitor,
                    subscriptionName: item.subscription.name,
                    createdOn: format(new Date(item.createdOn), 'yyyy/MM/dd')
                }));

                setTableData(data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    return <>
        <Grid container spacing={2}>
            <Grid item lg={12}>
                <Button variant="contained" onClick={() => navigate("/purchase")}> purchase</Button>
            </Grid>
            <Grid item lg={12} md={12} style={{ height: "80vh" }}>
                <ReactTable columns={billingTable} data={tableData} />
            </Grid>
        </Grid>
    </>
}

