import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import BotService from "../../service/BotService";
import Home from "../Home";
import BotButton from "./BotButton";
import ChatBox from "./ChatBox";

import chatBotLogo from '../../assets/images/web/GBot_large.png';
import { LINK } from "../../utils/apiConfig";
import { environment } from "../../utils/environments/environment";
import City from "../answerTypes/City";
import Confirmation from "../answerTypes/Confirmation";
import DatePiker from "../answerTypes/DatePiker";
import DateRangePiker from "../answerTypes/DateRangePiker";
import DateTimePiker from "../answerTypes/DateTimePiker";
import Email from "../answerTypes/Email";
import Exit from "../answerTypes/Exit";
import FilePicker from "../answerTypes/FilePiker";
import Language from "../answerTypes/Language";
import LongText from "../answerTypes/LongText";
import Mobile from "../answerTypes/Mobile";
import MultiSelect from "../answerTypes/MultiSelect";
import Number from "../answerTypes/Number";
import Rating from "../answerTypes/Rating";
import ShortText from "../answerTypes/ShortText";
import SingleSelect from "../answerTypes/SingleSelect";
import Start from "../answerTypes/Start";
import Swipper from "../answerTypes/Swipper";
import Test from "../../assets/data/Test";
import { toast } from "react-toastify";

export default function ChatBot({ showOnlyBot }) {

    const [data, setChatQue] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [showCB, setshowCB] = useState(false);
    const [img, setImg] = useState(true);
    const [messages, setMessages] = useState([]);
    const [sq, setSQ] = useState(1);
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [msg, setMsg] = useState([]);
    const [preSq, setPreSq] = useState([]);
    const [isTypingCompleted, setIsTypingCompleted] = useState(false);
    const [props, setprops] = useState('');
    const [showMyBot, setShowMyBot] = useState(false);
    const [chatStartTime, setChatStartTime] = useState('');
    const { botConfig } = useContext(UserContext);

    const [showHome, setShowHome] = useState(false);

    // console.log(JSON.stringify(Test));
    useEffect(() => {
        setprops(botConfig);
    }, [botConfig]);

    useEffect(() => {
        const urlSegments = window.location.href.split('/');
        if (!urlSegments.includes(LINK.BOT)) {
            setShowHome(true)
        }
    }, [])
    useEffect(() => {
        if (props !== '') {
            const filteredQuestion = props.questions.find(item => item.language === props.defaultLanguage);
            if (filteredQuestion) {
                setChatQue(JSON.parse(filteredQuestion.questions));
                // setChatQue(Test);
                setShowMyBot(true);
            }
        }
    }, [props]);

    function getMessageBySq(nextSq) {
        const result = data.find(item => item.sq === nextSq);
        setMessages(result);
    }

    const showChatBox = async () => {
        const result = await updateVisitor();
        if (result) {
            setIsHovered(false)
            increaseSQ(1);
            if (messages) {
                setImg(false);
                setshowCB(true);
            }
        }
    };

    const closeChatBox = () => {
        setImg(true);
        setshowCB(false);
        // setSQ(preSq);

        //Have to remove below code
        setSQ(1);
        setMsg([]);
        data.forEach(item => {
            item.ans.msg = null;
            item.ans.data = null;
        });
    };

    const increaseSQ = (nextSq) => {
        // if (sq <= data.length) {
        setCurrentMessageIndex(0);
        setPreSq(sq);
        setSQ(nextSq);
        getMessageBySq(nextSq);
        // }
    }

    const increaseSQWithDelay = (m, ans, data) => {
        setIsTypingCompleted(false)
        if (m.options === null || m.ansType === 'MultiSelect') {
            increaseSQ(m.nextQueSq);
        }
        else {
            increaseSQ(data.nextQueSq);
        }
        messages.ans.msg = ans;
        messages.ans.data = data;
    }

    const handleExit = () => {
        window.parent.postMessage(true, '*');

        placeEnquiry()
        closeChatBox();
        setSQ(1);
        setMsg([]);
        data.forEach(item => {
            item.ans.msg = null;
            item.ans.data = null;
        });

    };

    const placeEnquiry = async () => {
        const bot = {
            botId: props.id,
            data: JSON.stringify(msg),
            validator: environment.VALIDATOR,
            startsOn: chatStartTime
        }
        const response = await BotService.placeEnquiry(bot);

        if (response.status !== 200) {
            toast.warn("Can not place your enquiry, please subscribe any plan first")
        } else {
            console.log(response.data);
        }
    }

    const updateVisitor = async () => {
        const response = await BotService.updateVisitor(props.id);
        if (response.status !== 200) {
            toast.warn("Please subscribe any plan first")
            return false;
        } else {
            console.log(response.data.message);
            setChatStartTime(response.data.currentTime)
            return true;
        }
    }

    useEffect(() => {
        if (messages && messages.msg && currentMessageIndex < messages.msg.length) {
            if (!msg.some(item => item === messages)) {
                setMsg(OldMsg => [...OldMsg, messages]);
            }
            setCurrentMessageIndex(prevIndex => prevIndex + 1);
        }
    }, [messages, currentMessageIndex, msg]);

    const renderAnswerType = () => {
        switch (messages.ansType) {
            case 'Start':
                return <Start props={messages} increaseSQWithDelay={increaseSQWithDelay} />
            case 'Language':
                return <Language props={messages} increaseSQWithDelay={increaseSQWithDelay} setChatQue={setChatQue} data={props} />
            case 'MultiSelect':
                return <MultiSelect props={messages} increaseSQWithDelay={increaseSQWithDelay} />
            case 'FilePicker':
                return <FilePicker props={messages} increaseSQWithDelay={increaseSQWithDelay} />
            case 'SingleSelect':
                return <SingleSelect props={messages} increaseSQWithDelay={increaseSQWithDelay} />
            case 'Confirmation':
                return <Confirmation props={messages} increaseSQWithDelay={increaseSQWithDelay} />
            case 'ShortText':
                return <ShortText props={messages} increaseSQWithDelay={increaseSQWithDelay} />
            case 'AutoComplete':
            case 'City':
                return <City props={messages} increaseSQWithDelay={increaseSQWithDelay} />
            case 'Mobile':
                return <Mobile props={messages} increaseSQWithDelay={increaseSQWithDelay} />
            case 'DateTimePicker':
                return <DateTimePiker props={messages} increaseSQWithDelay={increaseSQWithDelay} />
            case 'DatePicker':
                return <DatePiker props={messages} increaseSQWithDelay={increaseSQWithDelay} />
            case 'Email':
                return <Email props={messages} increaseSQWithDelay={increaseSQWithDelay} />
            case 'Swiper':
                return <Swipper props={messages} increaseSQWithDelay={increaseSQWithDelay} />
            case 'LongText':
                return <LongText props={messages} increaseSQWithDelay={increaseSQWithDelay} />
            case 'DateRange':
                return <DateRangePiker props={messages} increaseSQWithDelay={increaseSQWithDelay} />
            case 'Rating':
                return <Rating props={messages} increaseSQWithDelay={increaseSQWithDelay} />
            case 'Number':
                return <Number props={messages} increaseSQWithDelay={increaseSQWithDelay} />
            case 'Exit':
                return <Exit props={messages} handleExit={handleExit} />
            default:
                return '';
        }
    }

    useEffect(() => {
        if (!showHome) {
            if (showMyBot) {
                showChatBox()
            }
        }
    }, [showMyBot]);


    return <>
        {showHome && !showOnlyBot && <Home showChatBox={showChatBox} img={img} props={props} />}
        <ChatBox
            showCB={showCB}
            // chatBotLogo={chatBotLogo}
            props={props}
            messages={messages}
            msg={msg}
            setIsTypingCompleted={setIsTypingCompleted}
            isTypingCompleted={isTypingCompleted}
            renderAnswerType={renderAnswerType}
            showHome={showHome}>
        </ChatBox>
        {showHome &&
            <BotButton
                showChatBox={showChatBox}
                closeChatBox={closeChatBox}
                img={img}
                isHovered={isHovered}
                setIsHovered={setIsHovered} />
        }
    </>
}