import { Grid, Paper } from "@mui/material";

const InfoCard = ({ props }) => {
  return <>
    <Grid container sx={{ gap: "2vh", justifyContent: "center" }}>

      <Grid item lg={2.5} md={2.7} sm={5.5} xs={12} >
        <Paper sx={{ height: "20vh", borderRadius: 3, boxShadow: "rgba(71, 143, 243, 0.25) 0px 2px 5px -1px, rgba(51, 143, 255, 0.3) 0px 1px 3px -1px" }}>
          <div className="chats-info">
            <div className="counter-msg">
              Total Remaining Chats
            </div>
            {props.totalChat === -1 ? <div >
              <span className="chat-count" style={{ color: "#5cc694" }}>Unlimited</span>
            </div> : <div >
              <span className="chat-count" style={{ color: "#5cc694" }}>{props.totalChat - props.usedChat}/</span> <span>{props.totalChat}</span>
            </div>}
          </div>
        </Paper>
      </Grid>

      <Grid item lg={2.5} md={2.7} sm={6} xs={12} >
        <Paper sx={{ height: "20vh", borderRadius: 3, boxShadow: "rgba(71, 143, 243, 0.25) 0px 2px 5px -1px, rgba(51, 143, 255, 0.3) 0px 1px 3px -1px" }}>
          <div className="chats-info">
            <div className="counter-msg">
              Total Used Chats
            </div>
            {props.totalChat === -1 ? <div >
              <span className="chat-count" style={{ color: "#f64442" }}>{props.usedChat}/</span> <span>Unlimited</span>
            </div> : <div >
              <span className="chat-count" style={{ color: "#f64442" }}>{props.usedChat}/</span> <span>{props.totalChat}</span>
            </div>}
          </div>
        </Paper>
      </Grid>

      <Grid item lg={2.5} md={2.7} sm={5.5} xs={12} >
        <Paper sx={{ height: "20vh", borderRadius: 3, boxShadow: "rgba(71, 143, 243, 0.25) 0px 2px 5px -1px, rgba(51, 143, 255, 0.3) 0px 1px 3px -1px" }}>
          <div className="chats-info">
            <div className="counter-msg">
              Total Remaining Visits
            </div>
            {props.totalVisitor === -1 ? <div >
              <span className="chat-count" style={{ color: "#ff944d  " }}>Unlimited</span>
            </div> : <div >
              <span className="chat-count" style={{ color: "#ff944d  " }}>{props.totalVisitor - props.usedVisitor}/</span> <span>{props.totalVisitor}</span>
            </div>}
          </div>
        </Paper>
      </Grid>

      <Grid item lg={2.5} md={2.7} sm={6} xs={12} >
        <Paper sx={{ height: "20vh", borderRadius: 3, boxShadow: "rgba(71, 143, 243, 0.25) 0px 2px 5px -1px, rgba(51, 143, 255, 0.3) 0px 1px 3px -1px" }}>
          <div className="chats-info">
            <div className="counter-msg">
              Total Used Visits
            </div>
            {props.totalVisitor === -1 ? <div >
              <span className="chat-count" style={{ color: "#34b8d0" }}>{props.usedVisitor}/</span>Unlimited
            </div> : <div >
              <span className="chat-count" style={{ color: "#34b8d0" }}>{props.usedVisitor}/</span> <span>{props.totalVisitor}</span>
            </div>}
          </div>
        </Paper>
      </Grid>
    </Grid >
  </>
};

export default InfoCard;
