import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

export default function ReactTable({columns, data}) {

    const table = useMaterialReactTable({
        columns,
        data,
      });

    return <MaterialReactTable table={table} />
}