import { CloudUpload } from "@mui/icons-material";

import { Button, Paper } from "@mui/material";
import { useState } from "react";
import { environment } from "./environments/environment";

export default function FilePicker({ btnName, handelFilePicker, fieldName }) {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > environment.MAX_FILE_SIZE) {
                alert("File size exceed 1MB, Please select smaller file")
            } else {
                setSelectedFile(file);
            }
        } else {
            setSelectedFile(file);
        }
    };
    return (
        <Paper elevation={3}>
            <input
                key={btnName}
                accept="image/*"
                id={`contained-button-file${fieldName}`}
                type="file"
                onChange={(event) => { handelFilePicker(event, fieldName); handleFileInputChange(event) }}
                style={{ display: 'none' }}
            />
            <label htmlFor={`contained-button-file${fieldName}`}>
                <Button sx={{
                    border: "1px solid #1976d280"
                }} variant="outlined" component="span" startIcon={<CloudUpload />} fullWidth>
                    {selectedFile ? selectedFile.name : btnName}
                </Button>
            </label>
        </Paper>
    );
}